import { checkItemsForSpecificType, TypeCheck } from './rules';
import { check as checkCold } from './cold';
import { getNeighbors } from './neighbors';

export const check : TypeCheck = (state, itemId) => {

	// check wether or not the given item is placed beneath or above a hot item with the cold function
	const coldCheck = checkCold(state, itemId);
	if(!coldCheck.correct) return {correct: false};// if the cold rule isnt met we do not have to check for bonus time and can return false

	//get top bottom items
	const itemsOnTop = state.items[itemId].placedOnTop;
	const itemsOnBottom = state.items[itemId].placedOnTopOf;

	//get place on grid
	const placeOnGrid = state.items[itemId].occupiedGridSpaceId;
	if(placeOnGrid === undefined) return {correct: true};

	//get left right items
	const neighbors = getNeighbors(state, placeOnGrid, itemId);

	//check if there is a cold item next to current item, if this is true give bonus time
	const result = {correct: true, bonus: checkItemsForSpecificType(state, [...neighbors,...itemsOnTop, ...itemsOnBottom], 3) ? 5 : 0};
	
	return result;
};