import { type } from 'os';
import { bagItems } from '../data/bagItems';
import { Level } from '../levelData';
import { LogicAction, LogicState } from './Logic';
import { getNeighbors } from './rules/neighbors';
import { checkItemWithType } from './rules/rules';

// intialize results data for a single level
export const initialize = (state: LogicState, action: LogicAction) => {
	if(action.type !== 'results-initialize') return state;

	state.results = {
		deliveries: [],
		complete: false,
	};

	// foreach delivery create an entry
	action.payload.level.deliveries.forEach((delivery, index) => {
		state.results.deliveries.push({
			id: index,
			items: [],
			complete: false,
			amountOfMistakes: {},
			bonusTime: 0
		});
		// foreach item create an entry
		delivery.bagItems.forEach((bagItem, itemId) => {

			const newItemResult : ItemResult = {
				id: itemId,
				types: [],
				correct: false,
			};

			// for each type create an entry
			const itemData = bagItems[bagItem];
			if(itemData)
				itemData.type.forEach((type) => {
					newItemResult.types.push({
						type: type,
						results: {correct: false}
					});
				});

			state.results.deliveries[index].items.push(newItemResult);

		});
	});

	return state;
};

// check the results of a single delivery
export const check = (state: LogicState, action: LogicAction) => {
	if(action.type !== 'results-check') return state;

	const currentDelivery = state.results.deliveries[action.payload.deliveryId];
	currentDelivery.amountOfMistakes = {};
	//
	currentDelivery.items.forEach(item => {
		item.correct = true;
		item.types.forEach(type => {
			const result = checkItemWithType(state, item.id, type.type);
			type.results = result;
			//set correct true or false in item
			if(!result.correct){
				item.correct = false;
				currentDelivery.amountOfMistakes[type.type] ? currentDelivery.amountOfMistakes[type.type] = currentDelivery.amountOfMistakes[type.type] + 1 : currentDelivery.amountOfMistakes[type.type] = 1;
			}
			//if there is bonus time earned, store it in item
			if(result.bonus){
				//individual bonus per item
				item.bonus = result.bonus;
				//total bonus time per delivery
				currentDelivery.bonusTime +=result.bonus;
			}
		});
	});
	
	currentDelivery.complete = true;

	//
	state.results.deliveries[action.payload.deliveryId] = {...currentDelivery};
	state.results = {deliveries: [...state.results.deliveries], complete: true};

	state.results.deliveries.map((delivery) => {
		if(!delivery.complete) state.results.complete = false;
	});

	return state;

};

export const emptyResultsState = {deliveries: [], complete: false};

export type ResultsAction = InitializeAction | CheckAction

export type InitializeAction = {
    type: 'results-initialize'
    payload: {
        level: Level
    }
}

export type CheckAction = {
	type: 'results-check'
	payload: {
		deliveryId: number
	}
}

export type ResultsState = {
    deliveries: Result[] // a result for each delivery
    complete: boolean; // indication of level completion
}

type Result = {
    id: number;
    items: ItemResult[]; // a result contains a single result for each item in a delivery
    complete: boolean; // indication of delivery completion
	amountOfMistakes: {[key:number]:number};
	bonusTime: number;
}

type ItemResult = {
    id: number;
    types: TypeResult[];
    correct: boolean; 
	bonus?: number;
} // an item contains multiple results for each type it has.
type TypeResult = {
    type: number; // indicates item type
    results: Results; // indicates wether or not the rules of the type have been followed correctly and if there is bonus time given
}


export type Results = {
	correct: boolean
	bonus?: number
}