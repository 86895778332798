import { BagGrid } from './grid/BagGrid';
import { DeliveryBar } from './DeliveryBar';
import { GameContextProvider } from '../../contexts/GameContext';
import { Results } from './result/Results';
import { ViewContainer } from '../containers/ViewContainer';
import { Tutorial } from './tutorial/Tutorial';
import { LogicContextProvider } from './logic/Logic';
import { TopBar } from './topbar/TopBar';
import CountersLabel from './Counters/CountersLabel';

export const Game = () => {
	return (
		<GameContextProvider>
			<LogicContextProvider>
				<ViewContainer activationGameState={2} style={{display: 'flex', flexDirection: 'column'}}>
					<TopBar/>
					<BagGrid/>					
					<CountersLabel/>
					<DeliveryBar/>
					<Tutorial/>
				</ViewContainer>
				<ViewContainer activationGameState={3}>
					<Results/>
				</ViewContainer>
			</LogicContextProvider>
		</GameContextProvider>
	);
};