import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import styled, { css } from 'styled-components';
import { Step } from '../data/tutorialData';

const TutorialContent = (props: {stepData: Step}) => {
	return ( 
		<>			
			{props.stepData.image && <Image src={props.stepData.image}/>}
			<Text>
				<StyledReactMarkdown>			
					{props.stepData.text}
				</StyledReactMarkdown>
			</Text>

		</>
	);
};

const Text = styled.div`
    height: 3.9em;
    display: flex;
    align-items: center;
    margin-block: auto;
`;

const StyledReactMarkdown = styled(ReactMarkdown)`
    & > p {
        margin: 0;
    }
`;

const Image = styled.div<{src: string | undefined}>`
    flex-grow: 1;
    width: 100%;
	border: 5px solid ${p => p.theme.colors.neutralLightest};
    margin-block: auto;
    background-color: ${p => p.theme.colors.neutralDarkest};

    border-radius: 1em;
    //if there is an image
    ${p => p.src && css`
        background-image: url(${p.src});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    `}
`;


export default TutorialContent;