import { useState } from 'react';
import styled from 'styled-components';
import { lock, star } from '../../assets/icons';
import { useGlobalContext } from '../../contexts/global/GlobalContext';
import { FadeIn, ScaleUp, SlideInLeft } from '../../style/animations';
import Button from '../components/Button';
import { levelData } from '../game/levelData';
import { AiFillLock, AiFillStar } from 'react-icons/ai';
import { ImCheckmark } from  'react-icons/im';
import IconButton from '../components/IconButton';
import {BsPlayFill} from 'react-icons/bs';


const Level = (props: {levelId: number, index: number, selected: number | undefined, onLevelSelect: (val: number) => void}) => {

	const {setGameState, setSelectedLevel, levelProgression} = useGlobalContext();

	const onLevelStart = (level: number) => {
		setSelectedLevel(level);
		setGameState(2);
	};

	return ( 
		<Container>
			<Button disabled={props.index > levelProgression} style={{width: '100%'}} onClick={() => props.onLevelSelect(props.index)}>
				{props.index > levelProgression ? <AiFillLock/> : props.index === levelProgression ? <AiFillStar/> : <ImCheckmark/> }
				{levelData[props.levelId].name}
				{props.index > levelProgression ? <AiFillLock/> : <AiFillStar/> }
			</Button>
			{ props.selected === props.index && <LevelInfo key={`levelselect-entry-${props.index}`}>
				<div>
					<p>{levelData[props.levelId].description}</p>
					{!(props.index > levelProgression) && <IconButton onClick={() => {onLevelStart(props.index);}}><BsPlayFill/></IconButton>}
				</div>
			</LevelInfo> }
		</Container>
	);
};

const Container = styled.div`
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 500px;
	width: 90%;
`;

const LevelInfo = styled.div`	
	border: solid 5px ${p => p.theme.colors.neutralLightest};
	padding: 1em;
	margin-top: 1em;
	text-align: left;
	width: 100%;
	border-radius: 1em;

	color: ${p => p.theme.colors.neutralLightest};

	& > div > p {
		margin: 0;
	}

	& > div > button {
		padding: 0.2em;
		margin-left: auto;
	}
	
	& > div {
		display: flex;
		align-items: center;
		animation: ${FadeIn} 1s forwards;
		animation-delay: 0.5s;
		opacity: 0;
		gap: 1em;
	}

	transform-origin: top;
	animation: ${ScaleUp} 0.5s forwards;

`;

 
export default Level;