import styled from 'styled-components';
import {HTMLAttributes} from 'react';

export const Tile = (props: TileProps) => {
	return (
		<Container className='tile' id={`${props.index}-baggrid-tile`} {...props}>
		</Container>
	);
};

// styled components

const Container = styled.div`
/* position: relative; */
    background-color: ${p => p.theme.colors.neutral};
	min-height: 0;
	min-width: 0;
`;

// types

interface TileProps extends HTMLAttributes<HTMLDivElement> {
	index: number;
}
