import { checkItemsForSpecificType, TypeCheck } from './rules';

export const check : TypeCheck = (state, itemId) => {
 
	// check wether or not the given item is placed beneath or above a hot item

	const itemsOnTop = state.items[itemId].placedOnTop;
	const itemsOnBottom = state.items[itemId].placedOnTopOf;

	const result = {correct: !checkItemsForSpecificType(state, [...itemsOnTop, ...itemsOnBottom], 4)};

	return result;
};