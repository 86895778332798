import { useState} from 'react';
import styled from 'styled-components';
import { logo } from '../../assets/images';
import { ViewContainer } from '../containers/ViewContainer';
import Level from './Level';
import { levelProgressionData } from './progressionData';

export const LevelSelect = () => {

	const [selected, setSelected] = useState<number>();

	const onLevelSelect = (level: number) => {
		if(selected === level){
			setSelected(undefined);
		}else {
			setSelected(level);
		}
	};
 
	return (
		<ViewContainer style={{overflowY: 'auto'}} activationGameState={1}>
			<Container>
				<img src={logo}/>
				<h2>Welcome to Bag it up!<br/>Select a level to start.</h2>
				{levelProgressionData && levelProgressionData.map((levelId, index) => 
					<Level selected={selected} onLevelSelect={onLevelSelect} levelId={levelId} index={index} key={`level-${levelId}`}/>
				)}
				<Spacer/>
			</Container>
		</ViewContainer>
	);
};

// styled components
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	img {
		width: 50%;
	}
	text-align: center;
`;

const Spacer = styled.div`
    height: 20px;
    width: 1px;
    flex-shrink: 0;
`;  



