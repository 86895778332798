import { sprite_bag, sprite_box, sprite_pizza_1, sprite_pizza_2, sprite_sack_1, sprite_sack_2, sprite_sack_3 } from '../../../assets/images/items';

export const pizza_1 = {
	sprite: sprite_pizza_1,
	size: {x: 3, y: 1},
	type: []
};
export const pizza_2 = {
	sprite: sprite_pizza_2,
	size: {x: 3, y: 2},
	type: []

};

export const bag_1 = {
	sprite: sprite_bag,
	size: {x: 2, y: 2},
	type: []
};

export const box_1 = {
	sprite: sprite_box,
	size: {x: 2, y: 1},
	type: []
};

export const sack_1 = {
	sprite: sprite_sack_1,
	size: {x: 1, y: 1},
	type: []
};

export const sack_2 = {
	sprite: sprite_sack_2,
	size: {x: 1, y: 2},
	type: []
};

export const sack_3 = {
	sprite: sprite_sack_3,
	size: {x: 2, y: 3},
	type: []
};

