import { LogicAction, LogicState } from './Logic';

export const initialize = (state: LogicState) => {

	state.dividers = [];

	for(let i = 1; i < state.grid.size.y; i++){
		state.dividers.push({
			enabled: false,
			positionY: i,
			locked: false,
			placedOnTop: []
		});
	}

	return state;
};

export const reset = (state: LogicState) => {
	state.dividers.forEach((divider) => {
		divider.enabled =  false;
		divider.locked = false;
		divider.placedOnTop = [];
	});
	return state;
};

export const toggleDivider = (state: LogicState, action: LogicAction) => {

	if(action.type !== 'dividers-toggle') return state;

	// toggle divider
	state.dividers[action.payload.dividerId].enabled = !state.dividers[action.payload.dividerId].enabled;

	return state;
};

export type DividersAction = InitializeAction | ToggleAction | ResetAction

export type InitializeAction = {
    type: 'dividers-initialize'
}

export type ResetAction = {
    type: 'dividers-reset'
}

export type ToggleAction = {
    type: 'dividers-toggle'
    payload: {
        dividerId: number;
    }
}

export type DividersState = DividerState[]
export type DividerState = {
    enabled: boolean;
    positionY: number;
	locked: boolean;
	placedOnTop: number[];
}