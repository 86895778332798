import { useEffect, useState, useMemo } from 'react';

import styled from 'styled-components';
import { logoWit } from '../assets/images';

interface Size {
    width: number;
    height: number;
}

const ForcePortrait = () => {

	const [size, setSize] = useState<Size>({width: window.innerWidth, height: window.innerHeight});

	// only render when the height of the device is lower than 600 px and the width is bigger than the height.
	const render = useMemo(() => {
		if(size.height < 600 && size.width > size.height){
			return true;
		}
		return false;
	}, [size]);

	useEffect(() => {
		window.onresize = resizeHandler;
	},[]);

	const resizeHandler = () => {
		setSize({height: window.innerHeight, width: window.innerWidth});
	};

	return (
		<>
			{ render &&
				<FullScreenMessage>
					<img src={logoWit}/>
					<Textbox>Turn your device vertically to play</Textbox>
				</FullScreenMessage>
			}
		</>
	);
};

//styled components

const FullScreenMessage = styled.div`
    position: absolute;
    left:0;
	top:0;
	bottom:0;
	right:0;
    z-index: 9001;

	width: 100vw;
	height: 100vh;

    background: ${p => p.theme.colors.primary};

	display: flex;
	flex-direction: column;
    justify-content: start;
    align-items: center;
	
	img {
		width: 25%;
	}
`;

const Textbox = styled.div`
    font-size: 5vh;
    text-align: center;
`;



export default ForcePortrait;
