import { LogicState } from '../Logic';
import { check as checkCold } from './cold';
import { check as checkHot } from './hot';
import { check as checkHeavy } from './heavy';
import { check as checkLight } from './light';
import { check as checkFrozen } from './frozen';
import { bagItems } from '../../data/bagItems';
import { Results } from '../results';

export const checkItemWithType = (state: LogicState, itemId: number, type: number) => {
	switch(type){
	case 1:
		return checkHeavy(state, itemId);
	case 2:
		return checkLight(state, itemId);
	case 3:
		return checkCold(state, itemId);
	case 4:
		return checkHot(state, itemId);
	case 5:
		return checkFrozen(state, itemId);
	default: 
		return {correct: true};
	}
};

// helper functions that are used by multiple rules

// check an array of itemId's for a specific type. If its included even once return true. Else false.
export const checkItemsForSpecificType = ( state: LogicState, items:number[], type: number) => {
	let included = false; // indicates wether a type is included in the given list of items

	items.forEach((itemId) => {
		const itemData = bagItems[state.items[itemId].dataId];
		if(!itemData) return;
		if(itemData.type.includes(type)){
			included = true;
		}
	});
	
	return included;
};

export const checkItemsForOtherTypes = (state: LogicState, items:number[], type: number) => {

	let included = false;

	items.forEach((itemId) => {
		const itemData = bagItems[state.items[itemId].dataId];
		included = !itemData.type.includes(type);
	});


	return included;

};

export type TypeCheck = (state: LogicState, itemId: number) => Results;  