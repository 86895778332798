import {useMemo} from 'react';
import styled from 'styled-components';
import { bagIcon, delivery as deliveryIcon } from '../../../assets/icons';
import { useGameContext } from '../../../contexts/GameContext';
import { BaseContainer } from '../../containers/BaseContainer';
import { levelData } from '../levelData';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import { useLogicContext } from '../logic/Logic';
import { ItemState } from '../logic/items';

const CountersLabel = (props: {style?: React.CSSProperties}) => {

	const {delivery, end, completedDeliveries, } = useGameContext();
	const {selectedLevel} = useGlobalContext();
	const {items} = useLogicContext();

	//usememo that gets all items in the delivery bar
	const itemsInBar = useMemo(() => {
		const baritems: ItemState[] = [];
		items.map((item) => {
			if(item.location === 'delivery-bar'){
				baritems.push(item);
			}
		},[]);
		return baritems;
	},[items]);
	

	return ( 
		<Container>
			<LabelContainer style={props.style}>
				<Icon image={deliveryIcon}/>
				<p translate='no'>{end ? completedDeliveries : delivery+1}/{levelData[selectedLevel].deliveries.length}</p>
				<Icon image={bagIcon}/>
				<p style={{marginRight: 0}} translate='no'>{itemsInBar.length}</p>
			</LabelContainer>
		</Container>
	);
};
const Container = styled(BaseContainer)`
    height: fit-content;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: row;
	position: relative;
    padding-top: 0;
`;

const LabelContainer = styled.label`

    background-color: ${p => p.theme.colors.neutralLightest};
    color: ${p => p.theme.colors.neutralDarker};
    padding: 0.4em 1em;
    border-radius: 5em;
    font-weight: bold;

    z-index: 1;
    display: flex;
    flex-direction: row;  
    align-items: center;
    line-height: 100%;

    & > p {
        margin: 0;
        margin-right: 1em;
    }    
`;

const Icon = styled.div<{image: string | undefined}>`
    background-image: url(${p => p.image});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

    aspect-ratio: 1/1;
    height: 1.5em;

    margin-right: 0.5em;
`;

export default CountersLabel;

