import styled, { css } from 'styled-components';

const Button = styled.button`
    background-color: ${p => p.theme.colors.primary};
    color: ${p => p.theme.colors.neutralLightest};

    align-items: center;
    position: relative;
    display: flex;	
    justify-content: space-between;
		
	border: none;
    padding: 0.7em 1.3em;
    border-radius: 2em;
	font-weight: 700;
	line-height: 100%;

	font-family: 'Lato';

	& > svg {
		font-size: 1.4em;
		visibility: hidden;
		margin-left: .5em;
	}
	
	& > svg:nth-child(1) {
		visibility: visible;
		margin-left: 0;
		margin-right: .5em;
	}

	cursor: pointer;

	:hover {
		background-color: ${p => p.theme.colors.primaryDark};
	}

	:disabled{
        filter: grayscale(100%);
        pointer-events: none; 
    }
	
`;


export default Button;