import styled, { css, keyframes } from 'styled-components';
import {HTMLAttributes, useEffect, useState} from 'react';
import { SlideInTop, SlideOutTop } from '../../../../style/animations';
import { useGameContext } from '../../../../contexts/GameContext';
import {FiClock} from 'react-icons/fi';
import { TimerResult } from 'react-timer-hook';

export const TimeCircle = (props: TimeCircleProps) => {

	const {end, bonusTime} = useGameContext();

	const [bonus, setBonus] = useState<number | undefined>();

	useEffect(() => {
		if(bonusTime !== 0){
			setBonus(bonusTime);
		}
	},[bonusTime]);

	return (
		<Container translate='no' _end={end} active={props.timer.isRunning}>
			<FiClock style={{fontSize: '1em'}}/>
			{props.timer.seconds + Math.ceil(props.timer.minutes * 60)}
			{ bonus &&
				<Bonus onAnimationEnd={() => setBonus(undefined)}>+ {bonus}</Bonus>
			}
		</Container>
	);
};

// styled components

const animation = keyframes`
	0%{
		opacity: 0;
	}
	10%{
		opacity: 1;
	}
	90%{
		opacity: 1;
	}
	100%{
		opacity:0;
	}
`;

const Container = styled.div<{_end: boolean, active: boolean}>`
    background-color: ${p => p.theme.colors.neutralLightest};
    color: ${p => p.theme.colors.neutralDarkest};

	${p => !p.active && css`
		color: grey;
		border: 2px solid grey;
	`}

    padding: 5px 50px 5px 15px;
    border-radius: 2em;
    font-size: 3em;
    
    line-height: 100%;
    /* align-items: center; */
	animation: ${p => p._end ? SlideOutTop : SlideInTop} 1s forwards;

	display: flex;
	gap: 10px;
	& > p {
		margin: 0;
	}
`;

const Bonus = styled.div`
	position: absolute;
	top:0;
	right: 0;
	display: flex;
	gap: 5px;
	align-items: center;
	transform: translate(50%, -50%);
	color: hsl(107, 47%, 46%);
	animation: ${animation} 3s forwards;
	background-color: ${ p => p.theme.colors.neutralLightest};
	font-size: 0.5em;
	font-weight: bold;
	border-radius: 2em;
	padding: 0;

	height: 30px;
    padding: 5px 12px;
	border: 2px solid hsl(107, 47%, 46%);
	font-weight: bold;
`;

// types

interface TimeCircleProps extends HTMLAttributes<HTMLDivElement> {
	time: number;
	timer: TimerResult;
}