import React, { useEffect } from 'react';
import styled from 'styled-components';
import firebase from 'firebase/compat/app';
import { Game } from './interface/game/Game';
import CustomThemeProvider from './style/CustomThemeProvider';
import { LevelSelect } from './interface/levelselect/LevelSelect';
import { GlobalContextProvider } from './contexts/global/GlobalContext';
import ContextProvider from './contexts/ContextProvider';
import { DeveloperConsole } from './interface/console/DeveloperConsole';
import ForcePortrait from './ForcePortrait';
import pipwerks from 'pipwerks-scorm-api-wrapper';

firebase.initializeApp({
	apiKey: 'AIzaSyAiO_eIQ1VOvk0Cn3ljd2gSar4tCcD-bLY',
	authDomain: 'immersive-firebase.firebaseapp.com',
	projectId: 'immersive-firebase',
	storageBucket: 'immersive-firebase.appspot.com',
	messagingSenderId: '330081859068',
	appId: '1:330081859068:web:015f236f2597ee064a2e79'
});

document.addEventListener('gesturestart', function (e) {
	e.preventDefault();
	
	// document.body.style.zoom = 0.99;
});

document.addEventListener('gesturechange', function (e) {
	e.preventDefault();

	// document.body.style.zoom = 0.99;
});
document.addEventListener('gestureend', function (e) {
	e.preventDefault();
	// document.body.style.zoom = 1;
});

const App = () => {

	useEffect(() => {
		pipwerks.SCORM.init();
	}, []);

	return (
		<CustomThemeProvider>
			<ContextProvider>
				<DeveloperConsole/>
				<ForcePortrait/>
				<Container>
					<Game/>
					<LevelSelect/>
				</Container>
			</ContextProvider>
		</CustomThemeProvider>
	);

};

// components

const Container = styled.div`
	height: 100%; width: 100%;
`;

export default App;
