import styled from 'styled-components';
import { BaseContainer } from '../containers/BaseContainer';
import { Item } from './items/Item';
import { useRef, useState, useEffect, useMemo} from 'react';
import { useGameContext } from '../../contexts/GameContext';
import { SlideIn, SlideOut } from '../../style/animations';
import { useGlobalContext } from '../../contexts/global/GlobalContext';
import { useLogicContext } from './logic/Logic';
import { ItemState } from './logic/items';
import Button from '../components/Button';
import {IoIosArrowForward, IoIosArrowBack} from 'react-icons/io';
import { AiOutlineArrowRight } from 'react-icons/ai';
import IconButton from '../components/IconButton';

export const DeliveryBar = () => {

	const {gameState} = useGlobalContext();
	const { items, dispatch, results } = useLogicContext();
	const { tracking, setBonusTime, reloading, setReloading, delivery, tutorial, setTutorialState, setCompletedDeliveries, level} = useGameContext();

	const content = useRef<HTMLDivElement>(null);

	const [reset, setReset] = useState<boolean>(false);
	const [showConfirmButton, setShowConfirmButton] = useState<boolean>(false);

	const [viewState, setViewState] = useState<number>(0);

	//usememo that gets all items in the delivery bar
	const itemsInBar = useMemo(() => {
		const baritems: ItemState[] = [];
		items.map((item) => {
			if(item.location === 'delivery-bar'){
				baritems.push(item);
			}
		},[]);
		return baritems;
	},[items]);
	
	//usememo that gets the current delivery data
	const currentDelivery = useMemo(() => {
		return level.deliveries[delivery];
	},[level, delivery]);	

	useEffect(() => {
		if(!currentDelivery) return;
		dispatch({type: 'item-initialize', payload: {items: currentDelivery.bagItems}});
		dispatch({type: 'dividers-reset'});
		dispatch({type: 'grid-reset'});
	}, [currentDelivery]);

	const confirmBag = () => {

		dispatch({type: 'results-check', payload: {deliveryId: delivery}});
		return;

	};

	const startReload = () => {
		const resultCurrentDelivery = results.deliveries[delivery];
		// logic for confirming a tutorial delivery
		if(tutorial !== undefined){
			// set a tutorial state based on the results. If there is a single mistake, set state to 3 else to 2.

			// tutorial wrong
			if(Object.values(resultCurrentDelivery.amountOfMistakes).length > 0){
				setTutorialState(3);
			}
			else{
				// tutorial completed
				setTutorialState(2);
				setCompletedDeliveries(a => a + 1); // increase the amount of completed deliveries

			}

		}
		// logic for confirming a standard delivery
		else{
			const mistakes = Object.values(resultCurrentDelivery.amountOfMistakes).length;
			if(currentDelivery.bonusTimeInSeconds){
				let reducedBonusTime = currentDelivery.bonusTimeInSeconds;
				for(let i = mistakes; i !== 0; i--){ reducedBonusTime = Math.ceil(reducedBonusTime / 2);}
				//sum bonus time earned from frozen items and correct placed items
				const totalBonusTime = reducedBonusTime + resultCurrentDelivery.bonusTime;
				setBonusTime( totalBonusTime );
			}
			setCompletedDeliveries(a => a + 1); // increase the amount of completed deliveries
			setReloading(true);
		}
	};

	useEffect(() => {
		if(!results || !results.deliveries[delivery]) return;
		
		//if the delivery is complete reload to go to next delivery
		if(results.deliveries[delivery].complete){
			startReload();
		}
	}, [results]);

	useEffect(() => {
		if(items)
			checkForCompleteButton();
	}, [items]);

	// checks wether no items are within the delivery bar
	const checkForCompleteButton = () => {
		let noChildren = true;

		for(let i = 0; i < items.length; i++){
			if(items[i].location === 'delivery-bar') noChildren = false;
		}

		setShowConfirmButton(noChildren);
	};

	const resetChildren = () => {
		if(reloading){
			setReset(true);
		}
	};

	useEffect(() => {
		if(!reloading){
			checkForCompleteButton();
			setReset(false);
		}
	},[reloading]);

	const item = document.getElementById('item');
	const defaultItemWidth = item?.getBoundingClientRect().width;
	const [itemWidth, setItemWidth] = useState<number>();
	//check if items in bar are overflowing (check if item width+the gap multiply by amount of items is higher than bar width)
	const overflow = content.current && itemWidth && (itemWidth+25)*itemsInBar.length > content.current.clientWidth;

	//if view state not is 0 or above set true for scrolling back
	const leftCheck = viewState != 0;
	//if items are overflow and the end is not reached set true (+25 for gap)
	const rightCheck = overflow && itemWidth && viewState !== -((itemsInBar.length-1)*(itemWidth+25));

	useEffect(() => {
		//store item width
		if(!itemWidth) setItemWidth(defaultItemWidth);
	},[defaultItemWidth]);

	const scroll = (direction: string) => {
		if(!itemWidth) return;
		switch(direction){
		case 'left':
			if(leftCheck)
				setViewState(e => e+(itemWidth+25));
			break;
		case 'right':
			if(rightCheck)
				setViewState(e => e-(itemWidth+25));
			break;
		}
	};

	useEffect(() => {
		//scroll back if an item is being removed from the bar
		if(viewState != 0){
			scroll('left');
		}
	},[itemsInBar]);
	
	return (
		<Container>
			<Subcontainer ref={content} reloading={reloading} onAnimationEnd={(resetChildren)} className='delivery-bar' >
				{/* left arrow */}
				{leftCheck &&
					<StyledIconButton onClick={() => scroll('left')}><IoIosArrowBack/></StyledIconButton>
				}
				{/* right arrow */}
				{rightCheck &&
					<StyledIconButton style={{right: 0}} onClick={() => scroll('right')}><IoIosArrowForward/></StyledIconButton>
				}	
				{/* complete button */}
				{showConfirmButton && 
				<Button style={{zIndex: 3,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',position: 'absolute', pointerEvents: `${tracking !== undefined ? 'none' : 'all'}`}} onClick={confirmBag} >
					<AiOutlineArrowRight/>
						Complete
					<AiOutlineArrowRight/>
				</Button>}
				{/* Items */}
				<Content view={viewState} className='delivery-bar' id={'delivery-bar'}>
					{gameState === 2 && items.map((item, index) => 
						<Item key={`item-${delivery}-${index}`} logicId={item.logicId} dataId={item.dataId} itemState={item} reset={reset}/>
					)}
				</Content>					
			</Subcontainer>
		</Container>
	);
};

// styled components

const Container = styled(BaseContainer)`
	position: relative;
    height: 20%;
	flex-shrink:0;
	padding: 0;
`;

const Subcontainer = styled.div<{reloading: boolean}>`
    background-color: ${p => p.theme.colors.neutralDark};
    height: 100%;
    box-sizing: border-box;
    padding-right: 0;
    overflow: hidden;
	// animation time for SlideOut should be less than the reloadDelay in gameContext;
	// animation time should not be shorter than BagGrid Container animation time + delay. Else you can see the delivery items dissapear.
	animation: ${p => p.reloading ? SlideOut : SlideIn} ${p => p.reloading ? '2s' : '.5s'} forwards;
	
	
`;

const Content = styled.div<{view: number}>`
    height: 100%;
	width: fit-content;
	/* padding: 2.5em 2em 2em 2em; */
	display: flex;
	align-items: center;
	gap: 2em;
	transform: translateX(${p => p.view+'px'});
	transition: transform 0.5s;
	z-index: 0;

	// Extra small screens
	@media (max-width: ${p => p.theme.responsive.media.sm}){
		padding: ${p => p.theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${p => p.theme.responsive.media.sm}){
		padding: ${p => p.theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.whitespace.lg}px;
	}

	// Extra large screens
	@media (min-width: ${p => p.theme.responsive.media.xl}){
		padding: ${p => p.theme.responsive.whitespace.xl}px;
	}

`;

const StyledIconButton = styled(IconButton)`
	position: absolute;
	top: 50%; 
	margin-inline: .5em;
	transform: translateY(-50%);
	z-index: 5;

	padding: 0.2em;
`;
