import { bag_1, box_1, pizza_1, pizza_2, sack_1, sack_2, sack_3 } from './bases';

export const bagItems : {[key: string]: BagItem} = {

	//#region basics without types

	'basic_sack_1' : {...sack_1},
	'basic_sack_2' : {...sack_2},
	'basic_sack_3' : {...sack_3},
	'basic_pizza_1' : {...pizza_1},
	'basic_pizza_2' : {...pizza_2},
	'basic_bag_1' : {...bag_1},
	'basic_box_1' : {...box_1},

	//#endregion


	//#region cold items

	'cold_sack_1' : {...sack_1, type:[3]},
	'cold_sack_2' : {...sack_2, type:[3]},
	'cold_sack_3' : {...sack_3, type:[3]},
	'cold_pizza_1' : {...pizza_1, type:[3]},
	'cold_pizza_2' : {...pizza_2, type:[3]},
	'cold_bag_1' : {...bag_1, type:[3]},
	'cold_box_1' : {...box_1, type:[3]},

	//cold and other type
	'cold_heavy_sack_1' : {...sack_1, type:[3,1]},
	'cold_heavy_sack_2' : {...sack_2, type:[3,1]},
	'cold_heavy_pizza_1' : {...pizza_1, type:[3,1]},
	'cold_heavy_pizza_2' : {...pizza_2, type:[3,1]},
	'cold_heavy_bag_1' : {...bag_1, type:[3,1]},
	'cold_heavy_box_1' : {...box_1, type:[3,1]},
	'cold_light_sack_1' : {...sack_1, type:[3,2]},
	'cold_light_sack_2' : {...sack_2, type:[3,2]},
	'cold_light_sack_3' : {...sack_3, type:[3,2]},
	'cold_light_pizza_1' : {...pizza_1, type:[3,2]},
	'cold_light_pizza_2' : {...pizza_2, type:[3,2]},
	'cold_light_bag_1' : {...bag_1, type:[3,2]},
	'cold_light_box_1' : {...box_1, type:[3,2]},

	//#endregion

	//#region hot items

	'hot_sack_1' : {...sack_1, type:[4]},
	'hot_sack_2' : {...sack_2, type:[4]},
	'hot_sack_3' : {...sack_3, type:[4]},
	'hot_pizza_1' : {...pizza_1, type:[4]},
	'hot_pizza_2' : {...pizza_2, type:[4]},
	'hot_bag_1' : {...bag_1, type:[4]},
	'hot_box_1' : {...box_1, type:[4]},

	//hot and other type
	'hot_heavy_sack_1' : {...sack_1, type:[4,1]},
	'hot_heavy_sack_2' : {...sack_2, type:[4,1]},
	'hot_heavy_pizza_1' : {...pizza_1, type:[4,1]},
	'hot_heavy_pizza_2' : {...pizza_2, type:[4,1]},
	'hot_heavy_bag_1' : {...bag_1, type:[4,1]},
	'hot_heavy_box_1' : {...box_1, type:[4,1]},
	'hot_light_box_1' : {...box_1, type:[4,2]},
	'hot_light_pizza_2' : {...pizza_2, type:[4,2]},
	'hot_light_bag_1' : {...bag_1, type:[4,2]},
	'hot_light_pizza_1' : {...pizza_1, type:[4,2]},
	'hot_light_sack_1' : {...sack_1, type:[4,2]},

	//#endregion

	// heavy items

	'heavy_sack_1' : {...sack_1, type:[1]},
	'heavy_sack_2' : {...sack_2, type:[1]},
	'heavy_sack_3' : {...sack_3, type:[1]},
	'heavy_pizza_1' : {...pizza_1, type:[1]},
	'heavy_pizza_2' : {...pizza_2, type:[1]},
	'heavy_bag_1' : {...bag_1, type:[1]},
	'heavy_box_1' : {...box_1, type:[1]},

	//#endregion


	//#region light items

	'light_sack_1' : {...sack_1, type:[2]},
	'light_sack_2' : {...sack_2, type:[2]},
	'light_sack_3' : {...sack_3, type:[2]},
	'light_pizza_1' : {...pizza_1, type:[2]},
	'light_pizza_2' : {...pizza_2, type:[2]},
	'light_bag_1' : {...bag_1, type:[2]},
	'light_box_1' : {...box_1, type:[2]},

	//#endregion


	//#region frozen items	
	'frozen_sack_1' : {...sack_1, type:[5]},
	'frozen_sack_2' : {...sack_2, type:[5]},
	'frozen_sack_3' : {...sack_3, type:[5]},
	'frozen_pizza_1' : {...pizza_1, type:[5]},
	'frozen_pizza_2' : {...pizza_2, type:[5]},
	'frozen_bag_1' : {...bag_1, type:[5]},
	'frozen_box_1' : {...box_1, type:[5]},

	//frozen and other type
	'frozen_heavy_sack_1' : {...sack_1, type:[5,1]},
	'frozen_heavy_sack_2' : {...sack_2, type:[5,1]},
	'frozen_heavy_pizza_1' : {...pizza_1, type:[5,1]},
	'frozen_heavy_bag_1' : {...bag_1, type:[5,1]},
	'frozen_heavy_box_1' : {...box_1, type:[5,1]},
	'frozen_light_sack_1' : {...sack_1, type:[5,2]},
	'frozen_light_sack_2' : {...sack_2, type:[5,2]},
	'frozen_light_sack_3' : {...sack_3, type:[5,2]},
	'frozen_light_pizza_1' : {...pizza_1, type:[5,2]},
	'frozen_light_bag_1' : {...bag_1, type:[5,2]},
	'frozen_light_box_1' : {...box_1, type:[5,2]},

	//#endregion

};


export type BagItem = {
	sprite: string,
	type: number[] // array of types 1 = heavy, 2 = light, 3 = cold, 4 = hot, 5 = frozen
	size: {x: number, y: number} // sizes in grid units.
}