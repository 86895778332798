import { HTMLAttributes, useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { useGameContext } from '../../../../contexts/GameContext';
import { TimeCircle } from './TimeCircle';

export const Time = () => {

	const {setEnd, bonusTime, setBonusTime, time, timerRunning, setTimerRunning} = useGameContext();

	const onTimerEnd = () => {
		setEnd(true);
		setTimerRunning(false);
	};

	const timer = useTimer({expiryTimestamp: new Date(), onExpire: onTimerEnd, autoStart: false});

	const initializeTimer = (newTime: number) => {
		const time = new Date();
		time.setSeconds(time.getSeconds() + newTime); // replace 10 with the given time from the level data.
		timer.restart(time, false);
	};

	const addBonusTime = (bonusTime: number) => {
		const time = new Date();
		time.setSeconds(time.getSeconds() + bonusTime + timer.seconds + Math.ceil(timer.minutes * 60)); // replace 10 with the given time from the level data.
		timer.restart(time);
	};

	useEffect(() => {
		if(bonusTime > 0){
			addBonusTime(bonusTime);
			// reset back to 0
			setBonusTime(0);
		}
	},[bonusTime]);

	useEffect(() => {
		if(timerRunning && !timer.isRunning)
			timer.start();
		else if(!timerRunning && timer.isRunning){
			timer.pause();
		}
	},[timerRunning]);

	useEffect(() => {
		initializeTimer(time);
	},[time]);

	return (
		<>
			{timer && 
				<TimeCircle time={timer.seconds} timer={timer}/>
			}
		</>
	);
};

// styled components


// types

export interface TimeChildProp extends HTMLAttributes<HTMLDivElement> {
	time: number,
	totalTime: number, 
	progress: number,
}

type TimerResult  = {
    seconds: number;
    minutes: number;
    hours: number;
    days: number;
    isRunning: boolean;
    start: () => void;
    pause: () => void;
    resume: () => void;
    restart: (newExpiryTimestamp: Date, autoStart?: boolean) => void;
}
