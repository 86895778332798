import { LogicAction, LogicState } from './Logic';
import { checkPlacement, CheckPlacementResult } from './placement';

// creates grid data based on given size from the payload.

export const initialize = (state: LogicState, action : InitializeAction) => {

	const newGridState : GridState = {
		spaces: [],
		size: {x: 0, y: 0}
	};

	// setSize

	newGridState.size.x = action.payload.size.x;
	newGridState.size.y = action.payload.size.y;

	// setSpaces

	const amountOfSpaces = newGridState.size.x * newGridState.size.y;
	let xValue = 0;
	for(let i = 1; i <= amountOfSpaces; i++){
		const yValue = Math.ceil(i / newGridState.size.x);
		xValue ++; if(xValue > 4) xValue = 1;
		newGridState.spaces.push({
			id: i - 1,
			position: {x: xValue, y: yValue},
			occupied: false,
			occupiedBy: undefined,
			occupiedByItemId: undefined,
			occupiedSpaces: [],
		});
	}

	return {...state, grid: newGridState};
};

export const reset = (state: LogicState) => {

	state.grid.spaces.forEach(space => {
		space.occupiedBy = undefined;
		space.occupied = false;
		space.occupiedByItemId = undefined;
		space.occupiedSpaces = [];
	});

	return state;
};

// place an item into the gridData
export const place = (state: LogicState, action: PlaceAction) => {
	const result : PlaceResult = {placed: false, check: undefined};

	const itemData = state.items[action.payload.itemId];

	// return if its being placed into the same gridSpace
	if(state.items[action.payload.itemId].occupiedGridSpaceId === action.payload.gridSpaceId) return state;

	// place into delivery-bar if place is called and either gridSpaceId and itemId are undefined.
	if(action.payload.gridSpaceId === undefined || action.payload.itemId === undefined){
	
		if(itemData.location === 'delivery-bar') return state;

		// set other grids if item was already on the grid...
		clearPreviousSpace(state, action);
		
		state.items = [...state.items]; // forces an items rerender
		state.items[action.payload.itemId] = {
			...itemData,
			location: 'delivery-bar',
			occupiedGridSpaceId : undefined,
			occupiedTileId : undefined,
		} ;
		result.placed = true;

		checkDividers(state, action);

	}

	// if they are not undefined, check for a placement on the grid.
	else{
		result.check = checkPlacement(state, action.payload.gridSpaceId, action.payload.itemId);
		if(result.check.possible) {
			// set other grids if item was already on the grid...
			clearPreviousSpace(state, action);
			

			// set items...
			state.items = [...state.items]; // forces an items rerender
			state.items[action.payload.itemId] = {
				...itemData,
				occupiedGridSpaceId : action.payload.gridSpaceId,
				occupiedTileId : action.payload.tileId,
				placedOnTopOf: result.check.itemsLocked ? result.check.itemsLocked : [],
				location: 'grid',
				occupiedDividerId: result.check.dividerLocked ? result.check.dividerLocked : undefined
			};

			// lock items if it has been placed on top of something
			result.check.itemsLocked && result.check.itemsLocked.forEach((itemId) => {
				state.items[itemId].placedOnTop.push(itemData.logicId);
				if(!state.items[itemId].locked) state.items[itemId].locked = true;
			});

			// set grid...

			const gridData = state.grid.spaces[action.payload.gridSpaceId];
			state.grid.spaces[action.payload.gridSpaceId] = {
				...gridData,
				occupied: true,
				occupiedBy: action.payload.gridSpaceId,
				occupiedByItemId: action.payload.itemId,
				occupiedSpaces: result.check.checkedIds ? result.check.checkedIds : [],
			};

			// check divider changes
			if(result.check.dividerLocked !== undefined){
				const divider = state.dividers[result.check.dividerLocked];
				divider.placedOnTop.push(itemData.logicId);
				divider.locked = true;
				state.dividers[result.check.dividerLocked] = divider;
			}


			result.placed = true;
			result.check.checkedIds && setOccupiedSpaces(state, action, result.check.checkedIds);

			checkDividers(state, action);
		}
	}

	return state;
};

const setOccupiedSpaces = (state: LogicState, action: LogicAction, spaces: number[]) => {

	if(action.type !== 'place' || action.payload.gridSpaceId === undefined) return;

	const grid = {...state.grid};
	for(let i = 0; i < spaces.length; i++){
		const updatedSpace = grid.spaces[spaces[i]];
		updatedSpace.occupied = true;
		updatedSpace.occupiedBy = action.payload.gridSpaceId;
		updatedSpace.occupiedByItemId = action.payload.itemId;
		state.grid.spaces[updatedSpace.id] = updatedSpace;
	}
	grid.spaces[action.payload.gridSpaceId].occupiedSpaces = [...spaces];
	state.grid = {...grid};
};

// clears previous data and checks dividers and locked objects
const clearPreviousSpace = (state: LogicState, action: LogicAction) => {
	if(action.type !== 'place') return;
	const itemData = state.items[action.payload.itemId];
	if(!itemData || itemData.occupiedGridSpaceId === undefined) return;

	// check if dividers can be unlocked
	if(itemData.occupiedDividerId !== undefined) {
		const divider = state.dividers[itemData.occupiedDividerId];
		const index =  divider.placedOnTop.indexOf(itemData.logicId);
		divider.placedOnTop.splice(index,1);
		state.dividers[itemData.occupiedDividerId] = divider;
		if(divider.placedOnTop.length === 0) divider.locked = false;
		state.dividers[itemData.occupiedDividerId] = divider;
	}

	// check if items can be unlocked
	itemData.placedOnTopOf.map((itemId) => {
		const item = state.items[itemId];
		const index = item.placedOnTop.indexOf(itemData.logicId);
		item.placedOnTop.splice(index, 1);
		if(item.placedOnTop.length === 0) item.locked = false;
		state.items[itemId] = item;
	});

	// set current space data to starting values

	const currentSpace = state.grid.spaces[itemData.occupiedGridSpaceId];
	currentSpace.occupiedSpaces.map((id) => {state.grid.spaces[id].occupied = false; state.grid.spaces[id].occupiedBy = undefined;});
	currentSpace.occupied = false;
	currentSpace.occupiedBy = undefined;
	currentSpace.occupiedByItemId = undefined;
	currentSpace.occupiedSpaces = [];

};

const checkDividers = (state: LogicState, action: LogicAction) => {
	state.dividers.forEach((divider) => {
		let locked = false;
		for(let i = 0; i < state.grid.spaces.length; i++){
			const space = state.grid.spaces[i];
			if(space.position.y === divider.positionY && space.occupied){
				locked = true;
				break;
			}
		}
		divider.locked = locked;
	});
};

export type PlaceResult = {
	placed: boolean;
	check: CheckPlacementResult | undefined
}

export const emptyGridState = {spaces: [], size: {x: 0, y: 0}};

// types

export type GridAction = InitializeAction | PlaceAction | ResetAction | CheckAction

export type InitializeAction = {
    type: 'grid-initialize'
    payload: {size: {x: number, y: number}}
}


export type CheckAction = {
	type: 'grid-check'
}

export type ResetAction = {
	type: 'grid-reset'
}

export type PlaceAction = {
    type: 'place',
    payload: {
        itemId: number
        gridSpaceId: number | undefined
		tileId: string | undefined
    },
}

export type GridState = {
    spaces: GridSpace[]
    size: {x: number, y: number}
    // changes: [
    //     {state: 'items', type: 'place', payload: 'blabla'} //changes for itemData
    // ]
}

export type GridSpace = {
	id: number
    position: {x: number, y: number}
	occupied: boolean;
	occupiedBy: number | undefined;
	occupiedByItemId: number | undefined;
	occupiedSpaces: number[];
}