import { bagItems } from './bagItems';

let columnSize : number;
let rowSize : number;
const gridSpaces : GridSpace[] = [];
export let dividers: boolean[] = [];

// initializes the grid data
export const initialize = (_columnSize: number, _rowSize: number) => {
	for(let i = 0; i < _rowSize; i++){
		for(let ii = 0; ii < _columnSize; ii++){
			gridSpaces[i + _rowSize * ii] = {
				id: i + _rowSize * ii,
				occupied: false,
				occupiedBy: undefined,
				occupiesIds: [],
				xPos: i,
				yPos: ii,
			};
		}
	}

	// add divider data for each columnSize - 1;
	dividers = [];
	for(let i = 1; i < _columnSize; i++){
		dividers.push(false);
	}

	columnSize = _columnSize;
	rowSize = _rowSize;
};

// reinitalized the grid data
export const reinitializeGrid = () => {
	initialize(columnSize, rowSize);
};

// places a bagItem into the grid data.
export const placeBagItem = (gridSpaceId: number, bagItemId: number, currentGridSpaceId?: number) => {
	const placementObject = checkPlacement(gridSpaceId, bagItemId, currentGridSpaceId);

	if(placementObject.possible){
		if(currentGridSpaceId !== undefined)
			removeBagItem(currentGridSpaceId);
		setOccupiedGridSpaces(placementObject.checkedIds, gridSpaceId);
		return(gridSpaces[gridSpaceId]);
	}
	else{
		return(null);
	}
};

// removes a bagItem from the grid data
export const removeBagItem = (gridSpaceId: number) => {
	gridSpaces[gridSpaceId].occupied = false;
	gridSpaces[gridSpaceId].occupiedBy = undefined;
	gridSpaces[gridSpaceId].occupiesIds.map((id) => {gridSpaces[id].occupied = false; gridSpaces[id].occupiedBy = undefined;});
	gridSpaces[gridSpaceId].occupiesIds = [];
};

// check wether the given bagItemId can fit into the given gridSpaceId
const checkPlacement = (gridSpaceId: number, bagItemId: number, currentGridSpaceId?: number) : {possible: boolean, checkedIds: number[]} => {
	const placementCheck : {possible: boolean, checkedIds: number[]} = {possible: true, checkedIds: []};
	const bagItem = bagItems[bagItemId];
	const gridSpace = gridSpaces[gridSpaceId];


	// when a gridSpace position is at the end of row/column, it returns false immediately
	if(bagItem.size.x > 1 && gridSpace.xPos + 1 === rowSize || bagItem.size.y > 1 && gridSpace.yPos + 1 === columnSize) {
		return {possible: false, checkedIds: []};
	}

	// check wether dividers are in the way (y axis only)
	const yAxisOccupied: number[] = [];
	for(let i = 0; i < bagItem.size.y; i++){
		yAxisOccupied.push(gridSpace.yPos + i);
	}

	if(!checkForDividers(yAxisOccupied)) return {possible: false, checkedIds: []};
	
	// check adjecent gridSpaces (to the left and down), based on bagItem size

	// loop through gridSpaces of the first row.
	for(let i = 0; i < bagItem.size.x; i++){

		if(checkGridSpaceForPlacement(gridSpaceId + i, currentGridSpaceId) && gridSpaces[gridSpaceId + i].yPos === gridSpace.yPos){
			placementCheck.checkedIds.push(gridSpaces[gridSpaceId + i].id);
		}
		else{
			placementCheck.possible = false; break;
		}

		// loop through gridSpaces of column based on i (i indicates how far into the x axis we are checking).
		for(let ii = 1; ii < bagItem.size.y; ii++){
			const checkingId = gridSpaceId + (columnSize * ii + i);

			// check if gridSpace along the Y axis is not occupied and whether or not it is outside  //&& gridSpace.yPos + ii !== columnSize
			if(checkGridSpaceForPlacement(checkingId, currentGridSpaceId)){
				placementCheck.checkedIds.push(gridSpaces[checkingId].id);
			}
			else{
				placementCheck.possible = false; break;
			}

		}

		// check 1 beneath the bottom row gridSpaces to check whether or not the placement is stable
		const bottomGridSpace = (gridSpaceId + i) + (rowSize * (bagItem.size.y - 1));
		if(!checkGridSpaceForStability(bottomGridSpace, currentGridSpaceId)){
			placementCheck.possible = false; break;
		}
	}


	return placementCheck;
};

const setOccupiedGridSpaces = (gridSpaceIds: number[], mainGridSpaceId: number) => {
	for(let i = 0; i < gridSpaceIds.length; i++){
		const updatedSpace = gridSpaces[gridSpaceIds[i]];
		updatedSpace.occupied = true;
		updatedSpace.occupiedBy = mainGridSpaceId;
		gridSpaces[updatedSpace.id] = updatedSpace;
	}
	gridSpaces[mainGridSpaceId].occupiesIds = [...gridSpaceIds];
};

export const setDivider = (index: number, state: boolean) => {
	// add checks before blindly setting the divider.
	// only toggle off if it has no occupied gridTiles above
	// only toggle on if it has no occupied gridTiles above
	dividers[index] = state;
};

const checkGridSpaceForPlacement = (gridSpaceId: number, itemCurrentGridSpaceId: number | undefined) : boolean => {
	const gridSpace = gridSpaces[gridSpaceId];

	// check if gridSpace exists.
	if(!gridSpace) return false;

	// check if the position of the base gridSpace (the one that is selected) is equal to the columnsize / rowSize. This indicates that the check encountered a gridSpace does not exist and that the item is bigger than the available space.	

	// check if gridSpace is occupied.
	if(gridSpace.occupied){
		// check if the gridSpace is occupied by the item that is trying to be placed here.
		if(itemCurrentGridSpaceId !== undefined && gridSpace.occupiedBy === itemCurrentGridSpaceId) return true;
		else{ return false; }
	}

	return true;

};

// checks whether or not the gridSpace is above a occupied gridSpace or gridSpace null.
const checkGridSpaceForStability = (gridSpaceId: number, itemCurrentGridSpaceId: number| undefined) => {

	const gridSpace = gridSpaces[gridSpaceId + rowSize]; // get the gridSpace below

	// need to check for dividers...
	if(!gridSpace || (gridSpace.occupied && gridSpace.occupiedBy !== itemCurrentGridSpaceId) || dividers[gridSpace.yPos - 1]) return true;
	else return false;
};

// check an array of y axis values if they are seperated by dividers
const checkForDividers = (values: number[]) => {
	let notDivided = true;
	for(let i = 1; i < values.length; i++){
		if(values[i] && dividers[values[i] - 1]) notDivided = false;
	}
	return notDivided;
};


// types

export type GridSpace = {
	id: number;
	occupied: boolean;
	xPos: number;
	yPos: number;
	occupiesIds: number[];
	occupiedBy: number | undefined;
}