import { GlobalContextProvider } from './global/GlobalContext';

// this provides 
const ContextProvider = (props: JSX.ElementChildrenAttribute) => {
	return (
		<GlobalContextProvider>
			{props.children}
		</GlobalContextProvider>
	);
};

export default ContextProvider;