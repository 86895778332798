import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ResultsState } from '../logic/results';

const tipmessages: string[] = [
	'You made various different mistakes, make sure you understand the different types!',
	'Don\'t crush items with heavy things on top.',
	'Place light items on top so they won\'t be crushed.',
	'Don\'t place hot items up or under colder items.',
	'Don\'t place cold items up or under hot items.',
	'Don\'t place frozen items up or under hot items.',
];

const Tip = (props: {results: ResultsState}) => {

	const allMistakes = useMemo(() => {
		//gets all the msitakes that are made within the different deliveries
		const arr: number[] = [];
		props.results?.deliveries.map((delivery, index) => {
			Object.keys(delivery.amountOfMistakes).map((mistake, index) =>{
				arr.push(parseInt(mistake));
			},[]);
		},[]);
		return arr;
	},[props.results?.deliveries]);
	

	const mostFrequent = useMemo(() => {
		let maxcount = 0;
		let element_having_max_freq;
		const s = new Set();
		for (let i = 0; i < allMistakes.length; i++) {
			//add uniques to a set
			s.add(allMistakes[i]);
		
			//count amount of times a mistake occurs
			let count = 0;
			for (let j = 0; j < allMistakes.length; j++) {
				if (allMistakes[i] == allMistakes[j])
					count++;
			}
			if (count > maxcount) {
				maxcount = count;
				element_having_max_freq = allMistakes[i];
			}
		}
	
		return allMistakes.length === 0 ? undefined // return undefined if length = 0, there are no mistakes
			: s.size === 1 ? allMistakes[0] 		// if there is only 1 mistake take that mistake as it is the most appearing
				: s.size === allMistakes.length ? 0 // return 0 if there is no most appearing item
					: element_having_max_freq;		// else return type that is most in mistakes

	},[allMistakes]);

	return ( 
		<Container>
			<Label>Tip!</Label>
			{props.results.complete === false ? <p>You ran out of time, place your items faster!</p> : mostFrequent === undefined ? <p>You made no mistakes, great work!</p> : <p>{tipmessages[mostFrequent]}</p>}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
    border: 2px solid ${p => p.theme.colors.primary};
    border-radius: 1em;
	text-align: center;
`;

const Label = styled.div`
    background-color: ${p => p.theme.colors.primary};
    border-radius:  10px 10px 0 0;
    padding: 10px 20px;
    font-weight: bold;
`;
 
export default Tip;