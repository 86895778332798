import { initialize as initializeGrid, reset as resetGrid, place} from './grid';
import { initialize as initializeItems } from './items';
import { initialize as initializeResults, check as checkResults} from './results';
import { initialize as initializeDividers, reset as resetDividers, toggleDivider } from './dividers';

import { LogicAction, LogicState } from './Logic';

export const reducer = (state : LogicState, action: LogicAction ) : LogicState => {
	
	switch(action.type){

	case 'grid-initialize' :
		return {...initializeGrid(state, action)};

	case 'grid-reset' :
		return {...resetGrid(state)};

	case 'results-initialize':
		return {...initializeResults(state, action)};

	case 'results-check':
		return {...checkResults(state, action)};

	case 'dividers-initialize' :
		return {...initializeDividers(state)};

	case 'dividers-reset' : 
		return {...resetDividers(state)};

	case 'dividers-toggle' :
		return {...toggleDivider(state, action)};

	case 'place' :
		return {...place(state, action)};
        
	case 'item-initialize' :
		return {...initializeItems(state, action)};

	default: 
		return state;
	}

};