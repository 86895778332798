import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useGameContext } from '../../../contexts/GameContext';
import { ViewContainer } from '../../containers/ViewContainer';
import { tutorialData } from '../data/tutorialData';
import TutorialContent from './TutorialContent';
import Button from '../../components/Button';
import ButtonsRow from './ButtonsRow';
import { AiOutlineArrowRight } from 'react-icons/ai';

export const Tutorial = () => {

	const {tutorial, tutorialState, setTutorialState, setReloading} = useGameContext();

	const [activeStep, setActiveStep] = useState<number>(0);
	const [continueDisabled, setContinueDisabled] = useState<boolean>(true);

	const onContinue = () => {
		//reset values when this tutorial is closed
		setActiveStep(0);
		setContinueDisabled(true);
		//close or end tutorial
		switch(tutorialState){
		case 1:
			setTutorialState(0);
			break;
		case 2:
			endTutorial();
			break;
		case 3:
			setTutorialState(0);
			break;
		}
	};

	const endTutorial = () => {
		setTutorialState(0);
		setReloading(true);
	};

	const currentTutorialSteps = useMemo(() => {
		//get current tutorial steps depending on state
		if(tutorial !== undefined)
			switch(tutorialState){
			case 1:
				return tutorialData[tutorial].introSteps;
			case 2:
				return tutorialData[tutorial].correctStep;
			case 3:
				return tutorialData[tutorial].wrongStep;
			}
	},[tutorial, tutorialState]);

	useEffect(() => {
		//if you reach the last step enable continue button
		if(currentTutorialSteps && activeStep+1 === currentTutorialSteps.length){
			//leave button on when you go back through the steps
			setContinueDisabled(false);
		}
	},[activeStep, tutorialState]);

	return (
		<>
			{currentTutorialSteps && tutorial !== undefined && tutorialState !== 0 &&
				<Background>
					<ViewContainer activationGameState={2}>
						<Container>
							{ currentTutorialSteps && currentTutorialSteps.map((step, index) => {
								return <>
									{activeStep === index && <TutorialContent key={`tutorialstep-${index}`} stepData={step}/>}
								</>;
							})}			
							{currentTutorialSteps.length != 1 && <ButtonsRow currentTutorialSteps={currentTutorialSteps} activeStep={activeStep} setActiveStep={setActiveStep}/>}
							<Button style={{marginTop:'auto'}} disabled={continueDisabled} onClick={onContinue}><AiOutlineArrowRight/>Continue<AiOutlineArrowRight/></Button>
						</Container>
					</ViewContainer>
				</Background>
			}
		</>

	);
};

// styled components
const Background = styled.div`
	position: absolute;
	inset:0;
	background-color: rgb(0,0,0,0.85);
	z-index: 3;
`;

const Container = styled.div`

    text-align: center;
    display: flex;
	gap: 1em;
	justify-content: center;
    flex-direction: column;
    align-items: center;

	height: 100%;
	width: 100%;
	
`;
