import { useContext, useEffect, createContext, ReactNode, useState, useMemo, useReducer, Dispatch} from 'react';
import { useGameContext } from '../../../contexts/GameContext';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import { DividersAction, DividersState } from './dividers';
import { emptyGridState, GridAction, GridState} from './grid';
import { ItemsAction, ItemsState } from './items';
import { reducer } from './LogicReducer';
import { emptyResultsState, ResultsAction, ResultsState } from './results';

const emptyState = {grid: emptyGridState, items: [], dividers: [], results: emptyResultsState};

const LogicContext = createContext<LogicContextProps>({
	state: emptyState,
	dispatch: () => null,
	grid: emptyGridState,
	items: [],
	dividers: [],
	results: emptyResultsState
});

const LogicContextProvider = (props: {children: ReactNode}) => {

	const {level} = useGameContext();
	const {gameState} = useGlobalContext();

	const [state, dispatch] = useReducer(reducer, emptyState);

	const items = useMemo(() => {
		return state.items;
	},[state.items]);

	const grid = useMemo(() => {
		return state.grid;
	},[state.grid]);

	const dividers = useMemo(() => {
		return state.dividers;
	},[state.dividers]);

	const results = useMemo(() => {
		return state.results;
	},[state.results]);

	useEffect(() => {
		dispatch({type: 'grid-initialize', payload: {size: {x: 4, y: 4}}});
		dispatch({type: 'dividers-initialize'});
	},[]);

	// reset results data
	useEffect(() => {
		if(gameState === 2)
			dispatch({type: 'results-initialize', payload: {level: level}});
	},[level, gameState]);

	const values : LogicContextProps = {
		state,
		dispatch,
		items,
		grid,
		dividers,
		results,
	};

	return (
		<LogicContext.Provider value={{...values}}>
			{props.children}
		</LogicContext.Provider>
	);
};

const useLogicContext = () => useContext(LogicContext);

// types

type LogicContextProps = {
	state : LogicState,
	dispatch: Dispatch<LogicAction>,
	grid: GridState,
	items: ItemsState,
	dividers: DividersState,
	results: ResultsState
}

export type LogicAction = GridAction | ItemsAction | DividersAction | ResultsAction

export type LogicState = {
	grid: GridState,
	items: ItemsState,
	dividers: DividersState
	results: ResultsState,
}


export {LogicContext, LogicContextProvider, useLogicContext};