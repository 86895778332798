import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import Plausible from 'plausible-tracker';
import App from './App';

const root = ReactDOM.createRoot(
document.getElementById('root') as HTMLElement
);

const { trackPageview } = Plausible({
	domain: 'bagitup.tqwi.nl',
	hashMode: true,
	trackLocalhost: true
});

trackPageview({
	url: location.hostname,
});

root.render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
);
