import { bagItems } from '../data/bagItems';
import { GridSpace } from './grid';
import { LogicState } from './Logic';

export const checkPlacement = (state: LogicState, gridSpaceId: number, itemId: number) => {
	
	const checkedIds: number[] = [];
	let dividerLocked;
	const itemsLocked : number[] = [];

	// check if placement is possible

	// check dividers

	// check spaces 

	const item = state.items[itemId];
	const itemData = bagItems[item.dataId];

	// go through the first row of spaces...
	for(let i = 0; i < itemData.size.x; i++){

		// get a position for the next space in the row
		const checkingPosForRow = {...state.grid.spaces[gridSpaceId].position};
		checkingPosForRow.x += i;

		// get the space tied to that position and add it the the array of checked spaces
		const checkingRowSpace = getSpaceFromPosition(state, checkingPosForRow);
		checkingRowSpace && checkedIds.push(checkingRowSpace.id);

		// determine wether we are checking the bottom row of an item
		let bottomValue = itemData.size.y === 1 ? true : false;

		// check a single space. If placement is not possible return result.
		const newRowResults = checkSpace(state, checkingRowSpace, itemId, bottomValue);
		if(!newRowResults.possible) return {...newRowResults, checkedIds: checkedIds};
		else{ 
			if(newRowResults.dividerLocked !== undefined) dividerLocked = newRowResults.dividerLocked;
			if(newRowResults.itemLocked !== undefined && !itemsLocked.includes(newRowResults.itemLocked)) itemsLocked.push(newRowResults.itemLocked);
		}
		// for each space, go through the height of the column.
		for(let ii = 1; ii < itemData.size.y; ii++){

			// get a position for the next space in the row
			const checkingPosForColumn = {...checkingPosForRow};
			checkingPosForColumn.y = ii + checkingPosForColumn.y;

			// get the space tied to that position and add it the the array of checked spaces
			const checkingColumnSpace = getSpaceFromPosition(state, checkingPosForColumn);
			checkingColumnSpace && checkedIds.push(checkingColumnSpace.id);

			// determine wether we are checking the bottom row of an item
			bottomValue = itemData.size.y === ii + 1 ? true: false;
			
			// check a single space. If placement is not possible return result.
			const newColumnResults = checkSpace(state, checkingColumnSpace, itemId, bottomValue);
			if(!newColumnResults.possible) return {...newColumnResults, checkedIds: checkedIds};
			else{ 
				if(newColumnResults.dividerLocked !== undefined) dividerLocked = newColumnResults.dividerLocked;
				if(newColumnResults.itemLocked !== undefined && !itemsLocked.includes(newColumnResults.itemLocked)) itemsLocked.push(newColumnResults.itemLocked);
			}
		}
	}
	return {possible: true, error: 0, checkedIds: checkedIds, dividerLocked: dividerLocked, itemsLocked: itemsLocked};
}; 

// checks a single grid space on specific placement rules
const checkSpace = (state: LogicState, space: GridSpace | undefined, itemId: number, bottom: boolean) => {
	const item = state.items[itemId];
	// if the space does not exist || return false error 1
	if(space === undefined) return {possible: false, error: 1};
	// when the space is occupied and the occupied space is not occupied by the given item || return false error 2
	if(space.occupied && space.occupiedBy !== item.occupiedGridSpaceId) return {possible: false, error: 2};
	// when space is at bottom check if it has support underneath || return false + error 3
	if(bottom){
		const spaceBeneath = getSpaceFromPosition(state, {x: space.position.x, y: space.position.y + 1});
		const divider = state.dividers[space.position.y - 1];

		if(divider && divider.enabled) return {possible: true, error: 0, dividerLocked: space.position.y - 1};
		if(spaceBeneath && spaceBeneath.occupied && spaceBeneath.occupiedBy !== item.occupiedGridSpaceId) return {possible: true, error: 0, itemLocked: spaceBeneath.occupiedByItemId};
		if((spaceBeneath && !spaceBeneath.occupied) || (spaceBeneath && spaceBeneath.occupied && spaceBeneath.occupiedBy === item.occupiedGridSpaceId)
		) return {possible: false, error: 3};
	}
	// check if there is no divider intersecting. return false + error 4
	else {
		const divider = state.dividers.filter((divider) => divider.positionY === space.position.y);
		if(divider.length > 0 && divider[0].enabled) return {possible: false, error: 4};
	}
	// if all checks are passed || return true with error 0
	return {possible: true, error: 0};
};

// gives back a space based on the position value given
const getSpaceFromPosition = (state: LogicState, position: {x: number, y: number}) => {
	const search = state.grid.spaces.find((space) => {
		if(space.position.x == position.x && space.position.y == position.y){
			return space;
		}
	});
	return search;
};

export type CheckPlacementResult = {
    possible: boolean;
    error: number;
    checkedIds?: number[];
	dividerLocked?: number | undefined;
	itemsLocked?: number[];
}