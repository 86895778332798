import styled from 'styled-components';
import Button from './Button';

const IconButton = styled(Button)`
  border-radius: 100%;
  padding: 1em;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;

  & > svg {
		margin: 0!important;
    padding: 0;
	}

`;

export default IconButton;