import styled, { css } from 'styled-components';
import {useState, PointerEvent, useEffect, useRef, HTMLAttributes, useMemo} from 'react';
import { BagItem as ItemData, bagItems } from '../data/bagItems';
import { useGameContext } from '../../../contexts/GameContext';
import TypeIcons from './TypeIcons';
import { useLogicContext } from '../logic/Logic';
import { ItemState } from '../logic/items';
import { GridSpace } from '../logic/grid';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import { levelData } from '../levelData';
import { relative } from 'path';

const enableDebugging = false;

export const Item = (props: BagItemProps) => {

	const {tracking, setTracking, reloading, end, delivery} = useGameContext();
	const {selectedLevel} = useGlobalContext();
	const {grid, results} = useLogicContext();

	// position which is given to the image.
	const [position, setPosition] = useState<Coordinates>({x:0,y:0});
	// size which is given to the image.
	const [size, setSize] = useState<Sizes>({width: 0, height: 0});
	// wether or not image should have the 'transition' property enabled.
	const [transition, setTransition] = useState<boolean>(false);

	const releaseTimeout = useRef<NodeJS.Timeout>();

	const gridSpaceData = useMemo(() => {
		if(props.itemState.occupiedGridSpaceId === undefined) return;
		return grid.spaces[props.itemState.occupiedGridSpaceId]; 
	},[props.itemState.occupiedGridSpaceId]);

	const itemData = useMemo<ItemData>(() => {
		return bagItems[props.dataId];
	},[props.dataId]);

	const image = useRef<HTMLDivElement>(null);
	const container = useRef<HTMLDivElement>(null);
	const referenceTile = useRef<HTMLElement>();
	const imageDuplicate = useRef<HTMLDivElement>(null);

	// debug

	const debugDot = useRef<HTMLDivElement>(null);
	const [debugPos, setDebugPos] = useState<Coordinates>({x:0,y:0});

	//#region setting variables on mount

	useEffect(() => {
		updateSize();
	},[]);

	// grow to real size when tracking this specific item
	useEffect(() => {
		if(tracking === props.logicId)
			updateSize();	
	},[tracking]);

	useEffect(() => {
		if(!transition){
			if(image.current) image.current.style.visibility = 'visible';
			if(imageDuplicate.current) imageDuplicate.current.style.visibility = 'hidden';
		}
	},[transition]);

	const [initialized, setInitialized] = useState(false);
	
	// on state change...
	useEffect(() => {
		onLocationChange();

		if(!initialized) {setInitialized(true); return;}

		startReleaseTransition();
		if(releaseTimeout.current){ clearTimeout(releaseTimeout.current);}
	}, [props.itemState]);

	// reset elements to their original node to prevent errors when unmounting
	useEffect(() => {
		if(props.reset){
			if(!container.current) return;
			const parent = document.getElementById('delivery-bar');
			parent && parent.append(container.current);
		}
	},[props.reset]);

	const onLocationChange = () => {
		if(!props.itemState.location || !container.current) return false;

		let newParent;
		switch(props.itemState.location){
		case 'delivery-bar':
			newParent = document.getElementById('delivery-bar');
			newParent && newParent.prepend(container.current);
			break;
		case 'grid':
			if(!props.itemState.occupiedTileId) return;
			newParent = document.getElementById(props.itemState.occupiedTileId);	
			newParent && newParent.append(container.current);
			break;
		}
	};

	// on mount

	useEffect(() => {
		window.addEventListener('resize',() => {updateSize(); updatePosition({x: 0, y:0});});
		
		const tile = document.getElementById('0-baggrid-tile');
		if(tile) {referenceTile.current = tile;}

		return () => {
			window.removeEventListener('resize',() => {updateSize(); updatePosition({x: 0, y:0});});
			document.removeEventListener('touchend', endTrackingTouches);
			document.removeEventListener('touchmove', updateTrackingForTouchEvents);
			document.removeEventListener('pointerup', endTrackingPointer);
			document.removeEventListener('pointermove', updateTrackingForPointerEvents);
		};
	},[]);

	useEffect(() => {
		debugDot.current && document.body.append(debugDot.current);
	},[debugPos]);

	//#endregion

	//#region sizing logic

	const updateSize = () => {
		if(!container.current) return;
		setSize({width: container.current.clientWidth, height: container.current.clientHeight});
		if(tracking !== undefined && referenceTile.current && itemData){
			setSize({width: referenceTile.current.clientWidth * itemData.size.x, height: referenceTile.current.clientHeight * itemData.size.y});
		}
	};

	const updatePosition = (positions: Coordinates) => {
		if(!positions) return;
		const newPos = positions;
		setPosition(newPos);
	};

	const onImageTransitionEnd = () => {
		setPosition({x: 0, y: 0});
		if(imageDuplicate.current) imageDuplicate.current.style.visibility = 'visible';
		if(image.current) image.current.style.visibility = 'hidden';

		image.current && container.current && container.current.append(image.current);
		setTransition(false);
		setTracking(undefined);

		if(releaseTimeout.current) clearTimeout(releaseTimeout.current);
	};
	
	//#endregion

	//#region tracking logic
   
	// is called when clicking the container.
	const initiateTracking = (e: PointerEvent<HTMLDivElement>) => {

		if(transition) return;

		image.current && document.body.append(image.current);

		setTracking(props.logicId);

		if(e.pointerType === 'mouse'){
			document.removeEventListener('pointerup', endTrackingPointer);
			document.removeEventListener('pointermove', updateTrackingForPointerEvents);
			document.addEventListener('pointerup', endTrackingPointer);
			document.addEventListener('pointermove',updateTrackingForPointerEvents);
		}
		else if(e.pointerType === 'touch'){
			document.removeEventListener('touchend', endTrackingTouches);
			document.removeEventListener('touchmove', updateTrackingForTouchEvents);
			document.addEventListener('touchend', endTrackingTouches);
			document.addEventListener('touchmove', updateTrackingForTouchEvents);
		}
		if(!image.current) return;
		updatePosition({x: e.clientX -  image.current.clientWidth / 2, y: e.clientY - image.current.clientHeight/ 2});
		// resize image to actual size
	};

	// is called everytime the pointer is moved while being held down
	const updateTrackingForPointerEvents = (e: globalThis.PointerEvent) => {
		if(!image.current) return;
		updatePosition({x: e.clientX -  image.current.clientWidth / 2, y: e.clientY - image.current.clientHeight/ 2});
	};

	const updateTrackingForTouchEvents = (e: TouchEvent) => {
		if(!image.current) return;
		updatePosition({x: e.touches[0].clientX -  image.current.clientWidth / 2, y: e.touches[0].clientY - image.current.clientHeight/ 2});
	};
	// can be merged with pointer or vice versa
	const endTrackingTouches = (e: TouchEvent) => {

		const bar = document.elementFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
		if(bar && container.current && bar.classList.contains('delivery-bar') && props.itemState.location != 'delivery-bar'){ 
			placeItemOnDeliveryBar(); endTracking();
			bar.append(container.current);
			// setLocation('delivery-bar'); 
			return; 
		}

		if(!image.current) return;
		const coords = {x: image.current.getBoundingClientRect().x + (image.current.clientWidth / 4), y: image.current.getBoundingClientRect().y + (image.current.clientHeight / 4)};
		const el = document.elementFromPoint(coords.x, coords.y);
		setDebugPos(coords);
		if(el && container.current){
			if(el.classList.contains('tile')){
				tryToPlaceItemOnGrid(el.id);
			}
			else{
				startReleaseTransition();
			}
		}
		endTracking();

	};

	const endTrackingPointer = (e: globalThis.PointerEvent) => {
		const bar = document.elementFromPoint(e.clientX, e.clientY);
		if(bar && container.current && bar.classList.contains('delivery-bar') && props.itemState.location != 'delivery-bar'){ 
			placeItemOnDeliveryBar(); endTracking();
			bar.append(container.current);
			// setLocation('delivery-bar'); 
			return; 
		}
		if(!image.current) return;
		const coords = {x: image.current.getBoundingClientRect().x + (image.current.clientWidth / 4), y: image.current.getBoundingClientRect().y + (image.current.clientHeight / 4)};
		const el = document.elementFromPoint(coords.x, coords.y);
		setDebugPos(coords);
		if(el && container.current){
			// element found with a tile class.
			if(el.classList.contains('tile')){
				tryToPlaceItemOnGrid(el.id);
			}
			// no element found with the right class.
			else{
				startReleaseTransition();
			}
		}
		endTracking();
	};


	// is called once the pointer is lifted
	const endTracking = () => {
        
		document.removeEventListener('touchend', endTrackingTouches);
		document.removeEventListener('touchmove', updateTrackingForTouchEvents);
		document.removeEventListener('pointerup', endTrackingPointer);
		document.removeEventListener('pointermove', updateTrackingForPointerEvents);
		setTracking(undefined);

		if(releaseTimeout.current) clearTimeout(releaseTimeout.current);
		releaseTimeout.current = setTimeout(() => {startReleaseTransition();}, 200);

	};

	const startReleaseTransition = () => {
		if(!container.current || !image.current) return;
		setTransition(true);
		image.current.addEventListener('transitionend', onImageTransitionEnd, {once: true});
		updatePosition({x: container.current.getBoundingClientRect().left, y:container.current.getBoundingClientRect().top});
		updateSize();
	};

	//#endregion

	//#region Placing items

	const {dispatch} = useLogicContext(); 

	const tryToPlaceItemOnGrid = (tileId: string) => {
		const gridSpaceId = parseInt(tileId.substring(0, tileId.indexOf('-'))); // gridSpaceId is taken from the css Id name
		// if the items is already placed in the given tile, start release transition.
		if(gridSpaceId === props.itemState.occupiedGridSpaceId){ 
			startReleaseTransition();
		}
		else{
			dispatch({type: 'place', payload:{itemId: props.logicId, gridSpaceId: gridSpaceId, tileId: tileId}}); 
		}
	};

	const placeItemOnDeliveryBar = () => {		
		if(!gridSpaceData || !container.current) return;

		if(props.itemState.occupiedGridSpaceId === undefined) startReleaseTransition();
		else dispatch({type: 'place', payload:{itemId: props.logicId, gridSpaceId: undefined, tileId: undefined}}); 
	};

	//#endregion

	
	const result = useMemo(() => {
		if(!results.deliveries[delivery] || !results.deliveries[delivery].items[props.logicId]) return;
		return results.deliveries[delivery].items[props.logicId];
	},[results]);

	return (
		<> {itemData &&
			<Container
				id='item'
				onPointerDown={(e) => {initiateTracking(e);}}
				tracking={tracking}
				ref={container}
				size={itemData.size}
				location={props.itemState.location}
				gridSpace={gridSpaceData}
				locked={props.itemState.locked}
				interactable={tracking === undefined && !end && !reloading}
				statusColor={(props.itemState.location === 'grid' && reloading && result) ? (result.correct ? (result.bonus ? 'rgb(38, 38, 115,0.5)' : 'rgb(0,128,0,0.5)' ) : 'rgb(255,0,0,0.5)') : 'none'}
			>
				<TypeIcons placed={props.itemState.location === 'delivery-bar' ? false : true} bagItemData={itemData}/>
				{/* Image */}
				<Image 
					locked={props.itemState.locked}				
					location={props.itemState.location}
					img={itemData.sprite} 
					ref={image} position={position} size={size} transition={transition} tracking={tracking}
				/>
				{/* duplicate which prevents jitter: */}
				<ImageDuplicate locked={props.itemState.locked} img={itemData.sprite} ref={imageDuplicate} style={{visibility: 'hidden'}}/>
				{/* {tracking} */}
				{ enableDebugging &&
				<DebugDot debugPos={debugPos} ref={debugDot}/>
				}
			</Container>
		}
		</>
	);
};

//#region styled components

const Container = styled.div<{statusColor: string,tracking: number | undefined, size: {x: number, y: number}, location: string, gridSpace: GridSpace | undefined, interactable: boolean, locked: boolean}>`
    position: relative;
    cursor: grab;
	height: ${p => p.location ==='delivery-bar' ? '100%' : ''};
    width: calc(${p => p.location === 'delivery-bar' ? '' : `${p.size.x * 100}%`});
    aspect-ratio: ${p => p.location === 'delivery-bar' ? '1/1' : p.size.x /p.size.y };
	pointer-events: ${p => !p.interactable || p.locked ? 'none' : 'all'};
	${p => p.location === 'delivery-bar' && css` 
		background-color: ${p => p.theme.colors.neutralDarker};
		outline: 2px solid ${p => p.theme.colors.primary};
	`}
	background-color: ${p => p.statusColor};
	outline: 1.5px solid ${p => p.statusColor};
`;

const Image = styled.div.attrs<{position: Coordinates, size: Sizes, transition: boolean, tracking: number | undefined}>((p) => ({
	style: {
		left: `${p.position.x}px`,
		top: `${p.position.y}px`,
		width: `${p.size.width}px`,
		height: `${p.size.height}px`,
		display: `${p.position ? 'none' : p.size? 'none' : p.tracking === undefined ? 'none' : ''}none`,
		transition: `${p.transition ? 'all ease-in-out 0.25s' : 'width ease-in-out 0.1s, height ease-in-out 0.1s'}`
	}
}))<{img: string,location: string, position: Coordinates, size: Sizes, transition: boolean, tracking: number | undefined, locked: boolean}>`

	position: absolute;
	//sprite
    background-image: url(${p => p.img});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	pointer-events: none;
	touch-action: none;
	z-index: 3;
	filter: ${p => p.locked && 'contrast(50%)'};

`;

const ImageDuplicate = styled.div<{img: string, locked: boolean}>`
	position: absolute;
	inset:0;
	/* background-color: purple; */
	pointer-events: none;

	background-image: url(${p => p.img});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	z-index: 3;
	filter: ${p => p.locked && 'contrast(50%)'};

`;

const DebugDot = styled.div<{debugPos: Coordinates}>`
	position: absolute;
	left: ${p => p.debugPos.x}px;
	top: ${p => p.debugPos.y}px;

	width: 100px;
	height: 100px;

	background-color: red;
`;


//#endregion

// types

type Coordinates = {x:number,y:number};
type Sizes = {width: number, height: number};
interface BagItemProps extends HTMLAttributes<HTMLDivElement> {
	logicId: number // index number for the array of items in Logic
	dataId: string // 
	itemState: ItemState
	reset: boolean;
}

//#endregion