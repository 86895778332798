import { checkItemsForSpecificType, TypeCheck } from './rules';

export const check : TypeCheck = (state, itemId) => {

	// check wether or not the given item is placed beneath or above a cold item

	const itemsOnTop = state.items[itemId].placedOnTop;
	const itemsOnBottom = state.items[itemId].placedOnTopOf;

	let result = {correct: !checkItemsForSpecificType(state, [...itemsOnTop, ...itemsOnBottom], 3)};
	if(result.correct){
	//check frozen as wel
		result = {correct: !checkItemsForSpecificType(state, [...itemsOnTop, ...itemsOnBottom], 5)};
	}
	return result;
};