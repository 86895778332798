import image_tutorial_0_step_1 from '../../../assets/images/tutorial/tutorial_0_step_1.png';
import image_tutorial_0_step_2 from '../../../assets/images/tutorial/tutorial_0_step_2.png';
import image_tutorial_1_step_1 from '../../../assets/images/tutorial/tutorial_1_step_1.png';
import image_tutorial_1_step_2 from '../../../assets/images/tutorial/tutorial_1_step_2.png';
import image_tutorial_2_step_1 from '../../../assets/images/tutorial/tutorial_2_step_1.png';
import image_tutorial_3_step_1 from '../../../assets/images/tutorial/tutorial_3_step_1.png';
import image_tutorial_3_step_2 from '../../../assets/images/tutorial/tutorial_3_step_2.png';
import image_tutorial_3_step_3 from '../../../assets/images/tutorial/tutorial_3_step_3.png';
import image_tutorial_5_step_1 from '../../../assets/images/tutorial/tutorial_5_step_1.png';
import image_tutorial_5_step_2 from '../../../assets/images/tutorial/tutorial_5_step_2.png';
import image_tutorial_7_step_1 from '../../../assets/images/tutorial/tutorial_7_step_1.png';
import image_tutorial_7_step_2 from '../../../assets/images/tutorial/tutorial_7_step_2.png';
import image_tutorial_9_step_1 from '../../../assets/images/tutorial/tutorial_9_step_1.png';
import image_tutorial_9_step_2 from '../../../assets/images/tutorial/tutorial_9_step_2.png';
import image_tutorial_9_step_3 from '../../../assets/images/tutorial/tutorial_9_step_3.png';
import image_tutorial_11_step_1 from '../../../assets/images/tutorial/tutorial_11_step_1.png';
import image_tutorial_11_step_2 from '../../../assets/images/tutorial/tutorial_11_step_2.png';
import image_tutorial_11_step_3 from '../../../assets/images/tutorial/tutorial_11_step_3.png';

export const tutorialData : TutorialData = [

	// level 1

	{	// 0
		introSteps: [
			{
				text: 'In this game your delivery bag  is represented by a grid. Drag items from the lower bar into the grid to complete a delivery.',
				image: image_tutorial_0_step_1 
			},
			{
				text: 'The icon in the upper right corner of an item indicates the size of that item',
				image: image_tutorial_0_step_2
			}
		],
		wrongStep: [{text: 'empty'}],
		correctStep: [{text: 'Perfect! This bag is correctly filled. Let\'s head to the next delivery.'}],
	},
	{	// 1
		introSteps: [
			{
				text: 'Items can\'t float around in the bag.',
				image: image_tutorial_1_step_1
			},
			{
				text: 'They must be placed on top of each other or at the bottom of the bag.',
				image: image_tutorial_1_step_2
			}
		],
		wrongStep: [{text: 'empty'}],
		correctStep: [{text: 'Great work! You are getting the hang of it.'}],
	},
	{	// 2
		introSteps: [
			{
				text: 'Items can only be placed on top of each other as long as the top item is fully supported by the item(s) below. Protruding items cause instability.',
				image: image_tutorial_2_step_1 
			}
		],
		wrongStep: [{text: ''}],
		correctStep: [{text: 'Awesome! You have already completed 3 deliveries.'}]
	},

	{	// 3
		introSteps: [
			{
				text: 'Each level will consist of multiple deliveries. On top of the delivery bar, you can see how many deliveries you need to complete in the current level.',
				image: image_tutorial_3_step_1
			},
			{
				text: 'You have a limited amount of time to complete all the deliveries of a level. The timer shows you how much time you have left.',
				image: image_tutorial_3_step_2
			},
			{
				text: 'With every completed delivery you earn extra time for the following deliveries.',
				image: image_tutorial_3_step_3
			}
		],
		wrongStep: [{text: ''}],
		correctStep: [{text: 'The next 2 deliveries will be timed, good luck!'}]
	},

	{//4
		introSteps: [
			{
				text: '',
			},
		],
		wrongStep: [{text: ''}],
		correctStep: [{text: 'Epic work! You successfully completed level 1.'}]
	},

	// level 2

	{	// 5
		introSteps: [
			{
				text: 'These symbols indicate if an item is hot or cold.',
				image: image_tutorial_5_step_1
			},
			{
				text: 'Hot and cold items can\'t be placed on top of each other, because this will affect their temperatures. ',
				image: image_tutorial_5_step_2
			}
		],
		wrongStep: [{text: 'This delivery is not correctly packed. Remember, you can\'t place hot and cold items on top of each other.'}],
		correctStep: [{text: 'Stellar work! Never place hot and cold items on top of each other.'}]
	},
	{	// 6
		introSteps: [
			{
				text: 'Let\'s see if you can figure out the next one.'
			}
		],
		wrongStep: [{text: 'This delivery is not correctly packed. Remember, you can\'t place hot and cold items on top of each other.'}],
		correctStep: [{text: 'You are nailing this!'}]
	},
	{	// 7
		introSteps: [
			{
				text: 'To help you separate hot and cold items, you can place dividers by clicking the "+" on the side of the grid.',
				image: image_tutorial_7_step_1
			},
			{
				text: 'Dividers act like a new floor. If you want to remove a divider, you first have to remove items on top of it.',
				image: image_tutorial_7_step_2
			}
		],
		wrongStep: [{text: 'This delivery is not correctly packed. Remember, you can place a divider by clicking the "+" to help you separate hot and cold items.'}],
		correctStep: [{text: 'Incredible! Dividers are very usefull when you want to seperate items.'}]
	},
	{	// 8
		introSteps: [
			{
				text: 'Here is another delivery that needs a divider.',
			}
		],
		wrongStep: [{text: 'This delivery is not correctly packed. Remember, you can place a divider by clicking the "+" to help you separate hot and cold items.'}],
		correctStep: [{text: 'You are a master! For the last two deliveries the time will be started. Good luck!'}]
	},

	//level 4
	{	//9 
		introSteps: [
			{
				text: 'Items can also be frozen, this is indicated with the above symbol.',
				image: image_tutorial_9_step_1
			},
			{
				text: 'Similar to cold items, frozen items can\'t be placed on top or under hot items.',
				image: image_tutorial_9_step_2
			},
			{
				text: 'It\'s not mandatory, but you earn 5 bonus seconds for each frozen item you place next to a cold item.',
				image: image_tutorial_9_step_3
			}
		],
		wrongStep: [{text: 'This is not quite right. Just like cold items, frozen items can\'t be placed on or under hot items.'}],
		correctStep: [{text: 'You are brilliant! Placing a frozen item next to a cold item will earn you 5 bonus seconds.'}]
	},
	{	//10
		introSteps: [
			{
				text: 'Let\'s practice with multiple frozen items!',
			}
		],
		wrongStep: [{text: 'This isn\'t right. Just like cold items, frozen items can\'t be placed on or under hot items. Although placing a frozen item next to a cold item earns you bonus time, it\'s not mandatory. '}],
		correctStep: [{text: 'Great work! It is useful but not mandatory to place a frozen item next to a cold item. Let\'s test your new skills with the following deliveries.'}]
	},

	//level 6
	{	//11
		introSteps: [
			{
				text: 'Some items are light, others are heavy. This will be indicated with the symbols above.',
				image: image_tutorial_11_step_1
			},
			{
				text: 'Heavy items will crush items below, unless that\'s a heavy item as well.',
				image: image_tutorial_11_step_2
			},
			{
				text: 'Light items can only support another light item. Other items will crush them.',
				image: image_tutorial_11_step_3
			}
		],
		wrongStep: [{text: 'Unfortunately, this is not correct. A heavier item will crush the lighter item below it! '}],
		correctStep: [{text: 'Excellent! Remember not to place heavier items on top of lighter items.'}]
	},
	{	//12
		introSteps: [
			{
				text: 'In the next delivery you have to keep in mind multiple rules.',
			}
		],
		wrongStep: [{text: 'Nope. Here multiple rules have to be followed. The hot-cold rule and the heavy-light rule. A divider can be of great help! '}],
		correctStep: [{text: 'Superb! A divider can be of great help to keep heavy and light objects separated. Time to put you to the test with the next deliveries.'}]
	}
];

type TutorialData = Tutorial[];
export type Tutorial = {
	introSteps: Step[]
	correctStep: Step[]
	wrongStep: Step[]
}
  
export type Step = {
	text: string
	image?: string
}
  