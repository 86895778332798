import styled from 'styled-components';
import {IoIosArrowForward, IoIosArrowBack} from 'react-icons/io';
import IconButton from '../../components/IconButton';

const ButtonsRow = (props: {currentTutorialSteps: any, activeStep: number, setActiveStep: (val: number) => void}) => {
	return ( 
		<Container>							
			<IconButton 
				disabled={!props.currentTutorialSteps[props.activeStep-1]}  
				onClick={() => props.setActiveStep(props.activeStep-1)}>
				<IoIosArrowBack/>
			</IconButton>
			<Dots>
				{[...Array(props.currentTutorialSteps.length)].map((x, index) => 
					<Dot selected={index === props.activeStep ? true : false} key={`${index}-dots`}></Dot>
				)}	
			</Dots>
			<IconButton 
				disabled={!props.currentTutorialSteps[props.activeStep+1]} 
				onClick={() => props.setActiveStep(props.activeStep+1)}>
				<IoIosArrowForward/>
			</IconButton>
		</Container>
	);
};

const Container = styled.div`
    display: flex;
	justify-content: center;
	justify-content: space-between;
	width: 100%;
	height: max-content;
`;

const Dots = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	width: 150px;
`;

const Dot = styled.div<{selected: boolean}>`
	background-color: ${p => p.selected ? `${p.theme.colors.primary}` : `${p.theme.colors.neutralLightest}`} ;

    height: 25%;
	aspect-ratio:1/1;
	border-radius: 100%;
`;

export default ButtonsRow;