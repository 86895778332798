import Plausible from 'plausible-tracker';
import { createContext, Dispatch, useContext, useState, SetStateAction, useEffect } from 'react';
import { levelProgressionData } from '../../interface/levelselect/progressionData';
import pipwerks from 'pipwerks-scorm-api-wrapper';

const GlobalContext = createContext<GlobalContextProps>({
	gameState: 0,
	setGameState: () => null,
	overwriteGameState: undefined,
	setOverwriteGameState: () => null,
	selectedLevel: 0,
	setSelectedLevel: () => null,
	levelProgression: 0,
	setLevelProgression: () => null,
});

const GlobalContextProvider = (props: JSX.ElementChildrenAttribute) => {

	const [gameState, setGameState] = useState<number>(1);
	const [overwriteGameState, setOverwriteGameState] = useState<number | undefined>();

	// detemines which level is loaded when showing the game view.
	const [selectedLevel, setSelectedLevel] = useState<number>(0);
	//number that states which level in the progrssion data is unlocked		
	const level = localStorage.getItem('levelProgression');
	const [levelProgression, setLevelProgression] = useState<number>(level ? parseInt(level) : 0);

	const completed = localStorage.getItem('completed');
	const [completedProgress, setCompletedProgress] = useState<string>(completed ? completed : 'false');

	useEffect(() => {
		const ov = localStorage.getItem('overwriteGameState');
		if(ov === 'undefined' || ov === null || ov === 'null') return;
		setOverwriteGameState(parseInt(ov));
		setGameState(parseInt(ov));
	},[]);

	const { trackEvent } = Plausible();

	//storing for levelprogression
	useEffect(() => {
		if(levelProgression >= levelProgressionData.length){
			console.log('game completed');
			trackEvent('Complete',{props:{level: levelProgression}}, {domain: 'bagitup.tqwi.nl'});
			localStorage.setItem('completed', 'true');
			setCompletedProgress('true');
			pipwerks.SCORM.set('cmi.completion_status', 'completed');
			pipwerks.SCORM.save();	
		}
		localStorage.setItem('levelProgression', JSON.stringify(levelProgression));
	}, [levelProgression]);	

	
	useEffect(() => {
		if(!level) return;

		const parsed = JSON.parse(level);

		setLevelProgression(parsed);
	},[]);

	const passedValues = {
		gameState,
		setGameState,
		overwriteGameState,
		setOverwriteGameState,

		//levels
		selectedLevel,
		setSelectedLevel,
		levelProgression, 
		setLevelProgression
	};		

	//#region render

	return (
		<GlobalContext.Provider value={{...passedValues}}>
			<>{props.children}</>
		</GlobalContext.Provider>
	);

	//#endregion
};

// types

type GlobalContextProps = {

	// general
	gameState: number,
	setGameState: (state: number) => void,
	overwriteGameState : number | undefined,
	setOverwriteGameState : Dispatch<SetStateAction<number| undefined>>;

	//game
	selectedLevel: number;
	setSelectedLevel: Dispatch<SetStateAction<number>>;
	levelProgression: number,
	setLevelProgression: Dispatch<SetStateAction<number>>

}

// exports

const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};
