import {useContext, useEffect, createContext, ReactNode, useState, Dispatch, SetStateAction, useMemo} from 'react';
import { reinitializeGrid } from '../interface/game/data/bagGrid';
import { Level, levelData } from '../interface/game/levelData';
import { useGlobalContext } from './global/GlobalContext';

const GameContext = createContext<GameContextProps>({
	tracking: undefined, 
	setTracking: () => {return null;}, 
	delivery: 0,
	setDelivery: () => null,
	bonusTime: 0, 
	setBonusTime: () => {return null;},
	reloading: false,
	setReloading: () => {return null;},
	end: false,
	setEnd: () => {return null;},
	timerRunning : false,
	setTimerRunning: () => null,
	time: 0,
	setTime: () => null,
	tutorial: 0,
	setTutorial: () => null,
	tutorialState: 0,
	setTutorialState: () => null,
	completedDeliveries: 0,
	setCompletedDeliveries: () => null,
	mistakesMade: 0,
	// setMistakesMade: () => null,
	level: levelData[0]
});

const levelEndDelay = 2200; // time in ms before showing the result screen;
const deliveryReloadDelay = 2200; // time in ms before a new delivery is loaded in.

const GameContextProvider = (props: {children: ReactNode}) => {

	const  {setGameState, selectedLevel, gameState} = useGlobalContext();

	// game flow
	const [end, setEnd] = useState<boolean>(false);
	const [reloading, setReloading] = useState<boolean>(false);

	// timer
	const [time, setTime] = useState<number>(0);
	const [timerRunning, setTimerRunning] = useState<boolean>(false);
	const [bonusTime, setBonusTime] = useState<number>(0);

	// bag items
	const [tracking, setTracking] = useState<number | undefined>(undefined);
	const [delivery, setDelivery] = useState<number>(-1);

	// tutorial
	const [tutorial, setTutorial] = useState<number | undefined>();
	const [tutorialState, setTutorialState] = useState<number>(1); // 0 === inactive 1 === intro 2 === correct 3 === wrong

	// track game data
	const [completedDeliveries, setCompletedDeliveries] = useState<number>(0); // tracks the amount of completed deliveries
	const [mistakesMade, setMistakesMade] = useState<number>(0); // tracks the amount of mistakes made by a user. (maybe change this to hold what mistakes were made?)|

	// set the new level data when selectedLevel changes
	const level = useMemo(() => {
		return levelData[selectedLevel];
	},[selectedLevel]);

	// on change reload, check wether we reached the final delivery or that we can proceed to the next delivery.
	useEffect(() => {

		// if(reloading && tutorial != undefined){
		// 	reinitializeGrid();
		// 	setTimeout(() => {
		// 		setTutorialState(1);
		// 		setTutorial(undefined);
		// 		setReloading(false);
		// 		setTimerRunning(true);
		// 	},deliveryReloadDelay);
		// 	return;
		// }

		if(reloading && !end){
			// end game if reloading true and it was the last delivery.
			if(level.deliveries.length === delivery + 1){
				setEnd(true);
				setTimerRunning(false);
			}
			// else continue to the next delivery
			else{
				setTimeout(() => {
					setDelivery(d => d + 1);
					setReloading(false);
				}, deliveryReloadDelay);
			}
		}
	}, [reloading]);

	// reinitialize the grid every delivery change
	useEffect(() => {

		reinitializeGrid();

		//check wether this is a tutorial delivery
		const newDelivery = level.deliveries[delivery];
		if(!newDelivery) return;
		if(newDelivery.tutorialIndex !== undefined){
			setTutorial(newDelivery.tutorialIndex);
		}
		else{
			if(tutorial !== undefined) setTutorial(undefined); 
			setTimeout(() => {
				setTimerRunning(true);
			}, deliveryReloadDelay / 2);
		}

	},[delivery]);

	useEffect(() => {
		if(tutorial !== undefined) setTutorialState(1);
		else {setTutorialState(0);}
	}, [tutorial]);

	useEffect(() => {
		if(end){
			setReloading(true);
			// gather result data and save it somewhere to use.
			// go to results screen after a delay.
			setTimeout(() => {setGameState(3);}, levelEndDelay);
		}
	},[end]);

	// initialize level when gameState changes to 2
	useEffect(() => {
		if(gameState === 2){
			initializeLevel();
			resetGameData();
		} else {
			setTime(0);
			setDelivery(-1);
		}
	},[gameState]);

	const initializeLevel = () => {

		if(!level) return;

		setTime(level.settings.startingTimeInSeconds);
		setEnd(false);
		setReloading(false);
		setDelivery(0);
		reinitializeGrid();

	};

	//#region functions

	const resetGameData = () => {
		setCompletedDeliveries(0);
		setMistakesMade(0);
	};

	//#endregion

	const values : GameContextProps = {
		
		tracking,
		setTracking,

		delivery,
		setDelivery,

		reloading,
		setReloading,

		end,
		setEnd,

		// Timer
		time,
		setTime,
		timerRunning,
		setTimerRunning,
		bonusTime,
		setBonusTime,

		// tutorial
		tutorial,
		setTutorial,
		tutorialState,
		setTutorialState,

		// game data
		completedDeliveries,
		setCompletedDeliveries,
		mistakesMade,

		//...
		level,

	};

	return (
		<GameContext.Provider value={{...values}}>
			{props.children}
		</GameContext.Provider>
	);
};

const useGameContext = () => useContext(GameContext);

// types

type GameContextProps = {

	// BagItems
    tracking: number | undefined;
    setTracking: Dispatch<SetStateAction<number | undefined>>;

	delivery: number;
	setDelivery: Dispatch<SetStateAction<number>>;

	// Timer
	time: number;
	setTime: Dispatch<SetStateAction<number>>;
	timerRunning: boolean;
	setTimerRunning: Dispatch<SetStateAction<boolean>>;
	bonusTime: number;
	setBonusTime: Dispatch<SetStateAction<number>>;

	reloading: boolean;
	setReloading: Dispatch<SetStateAction<boolean>>;

	end: boolean;
	setEnd: Dispatch<SetStateAction<boolean>>;

	// tutorial
	tutorial: number | undefined;
	setTutorial: Dispatch<SetStateAction<number | undefined>>
	tutorialState: number,
	setTutorialState: Dispatch<SetStateAction<number>>

	// game data
	completedDeliveries: number,
	setCompletedDeliveries: Dispatch<SetStateAction<number>>
	mistakesMade: number,

	//...
	level: Level
}


export {GameContext, GameContextProvider, useGameContext};