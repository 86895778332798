import { checkItemsForOtherTypes, TypeCheck } from './rules';

export const check : TypeCheck = (state, itemId) => {

	// check if given item is placed beneath another item that is not a light item.
 
	const itemsOnTop = state.items[itemId].placedOnTop;

	const result = {correct: !checkItemsForOtherTypes(state, [...itemsOnTop], 2)};

	return result;
};