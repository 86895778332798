import { createGlobalStyle } from 'styled-components';

//eslint-disable-next-line
const fonts = require('../assets/fonts/fonts.css');

const GlobalStyle = createGlobalStyle`

	${fonts};

	html {
		height: -webkit-fill-available;
		min-height: 100%;
		height: 100%;
		overflow: hidden;
	}

	body {
		margin:0;
		padding:0;
		height: 100%;
		overscroll-behavior: contain;
		overflow: hidden;
		background-color:${p => p.theme.colors.neutralDarker};
		color:${p => p.theme.colors.neutralLightest};
		font-family: 'Lato', 'sans-serif';

	}

	#root {
		height: 100%;
		overflow: hidden;
	}

	* {
		box-sizing: border-box;
		user-select: none;
	}

	h1, h2, h3 {
		margin: 0;
	}

	h1 {
		@media (max-width: ${p => p.theme.responsive.media.sm}){
			font-size: 1.6em;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${p => p.theme.responsive.media.sm}){
			font-size: 1.7em;
		}

		// Large screens
		@media (min-width: ${p => p.theme.responsive.media.lg}){
			font-size: 2.1em;

		}

		// Extra large screens
		@media (min-width: ${p => p.theme.responsive.media.xl}){
			font-size: 2.1em;
		}
	}

	p, button, th, tr, td { 
		// Extra small screens
		@media (max-width: ${p => p.theme.responsive.media.sm}){
			font-size: 0.9em;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${p => p.theme.responsive.media.sm}){
			font-size: 1.1em;
		}

		// Large screens
		@media (min-width: ${p => p.theme.responsive.media.lg}){
			font-size: 1.4em;

		}

		// Extra large screens
		@media (min-width: ${p => p.theme.responsive.media.xl}){
			font-size: 1.4em;
		}
	}

	/* custom scrollbar */

	/* width */
	::-webkit-scrollbar {
		width: 7.5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${p => p.theme.colors.neutral};
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		background:  ${p => p.theme.colors.neutralLightest}; 
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555; 
	}

`;

export default GlobalStyle;
