import styled, { css } from 'styled-components';
import { sizeList } from '../../../assets/icons/gridsizes';
import { typesList } from '../../../assets/icons/types';
import { BagItem as BagItemType} from '../data/bagItems';

const TypeIcons = (props:{bagItemData: BagItemType, placed: boolean}) => {

	return ( 
		<Container placed={props.placed}  >
			<IconRow placed={props.placed}  y={props.bagItemData.size.y} x={props.bagItemData.size.x}>
				{/* type icons */}
				{props.bagItemData.type.map((typeId, index) => {
					return (<img style={{aspectRatio: '1/1'}} width="25%" src={typesList[typeId-1]} key={`type-number-${index}`}/>);
				},[])}
				{/* size icon */}
				{!props.placed && <img style={{aspectRatio: '1/1'}} width="25%" src={sizeList[`${props.bagItemData?.size.x.toString()}${props.bagItemData?.size.y.toString()}` ]}/>}
			</IconRow>
		</Container>
	);
};

const IconRow = styled.div<{placed: boolean, x: number, y: number}>`
	display: flex;
	justify-content: flex-end;
	gap: 2px;
	transform: translate(14%, -50%);	
	
	${p => p.placed && css`	
		justify-content: center;
		align-items: center;
		transform: translate(0,0);	
		& img {
			height: 25px;
			width: 25px;
		}
		flex-direction: ${p.y > p.x ? 'column' : 'row'};
	`}
`;

const Container = styled.div<{placed: boolean}>`
	position: absolute;
	z-index: 4;
	pointer-events: none;

	${p => p.placed && css`	
		display: flex;
		justify-content: center;
		height: 100%;
		width: 100%;
	`}
`;
 
export default TypeIcons;