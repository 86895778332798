import heavy from './1.png';
import frozen from './4.png';
import light from './3.png';
import cold from './2.png';
import hot from './5.png';

export const typesList: string[] = [
	heavy,
	light,
	cold,
	hot,
	frozen
];