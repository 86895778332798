import styled from 'styled-components';
import AARContainer from '../../containers/AARC';
import { BaseContainer } from '../../containers/BaseContainer';
import { initialize } from '../data/bagGrid';
import { Tile } from './Tile';
import {useEffect } from 'react';
import { SlideInLeft, SlideOutRight } from '../../../style/animations';
import { useGameContext } from '../../../contexts/GameContext';
import { useLogicContext } from '../logic/Logic';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import {useMemo} from 'react';
import { levelData as ld } from '../levelData';
import IconButton from '../../components/IconButton';
import { FaMinus, FaPlus } from 'react-icons/fa';

const gridLineSize = 6;

export const BagGrid = () => {

	const {reloading, delivery} = useGameContext();
	const {dividers, dispatch} = useLogicContext();
	const {selectedLevel} = useGlobalContext();
	const toggleDivider = (index: number) => {
		dispatch({type: 'dividers-toggle', payload: {dividerId: index}});
	};

	// store levelData based on selectedLevel from the global context.
	const levelData = useMemo(() => {
		return ld[selectedLevel]; 
	},[selectedLevel]);

	useEffect(() => {
		initialize(4, 4);
	},[]);

	// store current divider state
	const dividerState = useMemo(() => {
		if(delivery === -1){
			return false;
		} else {
			return levelData.deliveries[delivery].dividers; 
		}
	},[delivery]);

	return (
		<Container reloading={reloading}>
			<AARContainer fitParent aspectRatio='1/1' style={{paddingLeft: '10px'}}>
				<VisualGrid id={'visual-grid'}>
					{dividerState !== false && dividers.map((divider, index) => 
						<Divider key={`divider-${index}`} index={index} enabled={divider.enabled}>
							{<Toggle onClick={() => toggleDivider(index)} disabled={divider.locked}>{divider.enabled ? <FaMinus/> : <FaPlus/>}</Toggle>}
						</Divider>
					)}
					{ [...Array(4 * 4)].map((value, index) => 
						<VisualTile key={`visual-tile-${index}`} index={index}/>

					)}
				</VisualGrid>
				<Grid id={'bag-grid'}>
					{ [...Array(4 * 4)].map((value, index) => 
						<Tile key={`baggrid-tile-${index}`} index={index}/>
					)}
				</Grid>
			</AARContainer>
		</Container>
	);
};

// styled components

const Container = styled(BaseContainer)<{reloading: boolean}>`
	position: relative;
	animation: ${p => p.reloading ? SlideOutRight : SlideInLeft} ${p => p.reloading ? '1s' : '1s'} forwards;
	animation-delay: ${p => p.reloading ? '1s' : ''};

`;

const Grid = styled.div`
	background-color: ${p => p.theme.colors.primaryDarker};
    height: 100%; width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
	outline: solid ${gridLineSize / 2}px ${p => p.theme.colors.primary};
	border-radius: 20px;
	padding: 10px;
`;

const VisualGrid = styled(Grid)`
	position: absolute;
	inset:0;
	pointer-events: none!important;
`;

const VisualTile = styled.div<{index: number}>`

	position: relative;
	pointer-events: none;

	&:after{
		content: '';
		position: absolute;
		inset:0px;
		border: solid ${gridLineSize / 2}px rgba(255,255,255,1);
		pointer-events: none;
	}
`;

const Divider = styled.div<{index: number, enabled: boolean}>`

	position: absolute;

	/* grid-column: 1 / -1; */
	width: 100%;
	grid-row: ${p => p.index + 2} / ${p => p.index + 2};

	z-index: 1;

	&::after{
		content: '';
		position: absolute;
		inset:0;
		transform: translateY(-50%);
		border-bottom: ${p => p.enabled ? `10px solid ${p.theme.colors.primary}` : ''};
	}
`;

const Toggle = styled(IconButton)`
	position: absolute;
	right:0;
	top:50%;
	padding: 0.2em;
	font-size: 1em;

	z-index: 2;
	transform: translate(50%, -50%);

	pointer-events: all;
	border: none;

`;