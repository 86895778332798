import { LogicState } from './Logic';

// creates grid data based on given size from the payload.

export const initialize = (state: LogicState, action : InitializeAction) => {
	const newItemsState : ItemsState = [];
	action.payload.items.forEach((dataId, index)=> {
		newItemsState.push({logicId: index, dataId: dataId, location: 'delivery-bar', occupiedGridSpaceId: undefined, occupiedTileId: undefined, locked: false, placedOnTop: [], placedOnTopOf: [], occupiedDividerId: undefined});
	});
	return {...state, items: newItemsState};
};

// types

export type ItemsAction = InitializeAction

export type InitializeAction = {
    type: 'item-initialize'
	payload: {items: string[]};
}

export type ItemsState = ItemState[]
export type ItemState = {
	logicId: number, // id of the item in the logic state
	dataId: string, // id of the item in the data
	location: 'grid' | 'delivery-bar',
	occupiedGridSpaceId: number | undefined
	occupiedTileId: string | undefined
	locked: boolean;
	placedOnTop: number[]; // array of itemId's of which are using the current item as stable ground.
	placedOnTopOf: number[]; // array of itemId's of which are being used by the current item as stable ground;
	occupiedDividerId: number | undefined;
}