import { bagItems } from '../../data/bagItems';
import { LogicState } from '../Logic';

// gives back left or right (depending on type) neughbors of given item 
export const getNeighbors = (state: LogicState, gridSpaceId: number, itemId: number) => {

	//current item info
	const item = state.items[itemId];	
	const itemWidth = bagItems[item.dataId].size.x;
	const itemHeight = bagItems[item.dataId].size.y;

	const currentGridSpace = state.grid.spaces[gridSpaceId];
	const neighbors: number[] = [];

	//check left
	for(let i = 0; i < itemHeight; i++){
		const checkedGridSpace = getSpaceFromPosition(state, {x: currentGridSpace.position.x-1, y: currentGridSpace.position.y+i});
		if(checkedGridSpace && checkedGridSpace.occupiedByItemId !== undefined && !neighbors.includes(checkedGridSpace.occupiedByItemId))
			neighbors.push(checkedGridSpace?.occupiedByItemId);
	}

	//check right
	if(itemWidth > 1){
		for(let i = 0; i < itemHeight; i++){
			const checkedGridSpace = getSpaceFromPosition(state, {x: currentGridSpace.position.x+itemWidth, y: currentGridSpace.position.y+i});
			if(checkedGridSpace && checkedGridSpace.occupiedByItemId !== undefined && !neighbors.includes(checkedGridSpace.occupiedByItemId))
				neighbors.push(checkedGridSpace?.occupiedByItemId);
		}
	} else {
		for(let i = 0; i < itemHeight; i++){
			const checkedGridSpace = getSpaceFromPosition(state, {x: currentGridSpace.position.x+1, y: currentGridSpace.position.y+i});
			if(checkedGridSpace && checkedGridSpace.occupiedByItemId !== undefined && !neighbors.includes(checkedGridSpace.occupiedByItemId))
				neighbors.push(checkedGridSpace?.occupiedByItemId);
		}
	}

	return neighbors;
};

// gives back a space based on the position value given
const getSpaceFromPosition = (state: LogicState, position: {x: number, y: number}) => {
	const search = state.grid.spaces.find((space) => {
		if(space.position.x == position.x && space.position.y == position.y){
			return space;
		}
	});
	return search;
};