import styled from 'styled-components';
import { BaseContainer } from '../../containers/BaseContainer';
import { levelData as ld} from '../levelData';
import { Time } from './time/Time';
import { BsQuestionLg } from 'react-icons/bs';
import { useGameContext } from '../../../contexts/GameContext';
import IconButton from '../../components/IconButton';

export const TopBar = () => {
	const {tutorial, setTutorialState, reloading} = useGameContext();

	return (
		<Container>
			{tutorial !== undefined && <IconButton onClick={() => setTutorialState(1)} disabled={reloading}><BsQuestionLg/></IconButton> }
			{ tutorial === undefined && <Time/>}
		</Container>
	);
};

// styled components

const Container = styled(BaseContainer)`
    /* height: 100px; */
	height: fit-content;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: row;
	padding: 0;
	position: relative;
`;


