import { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import Button from '../../components/Button';
import { levelData as ld } from '../levelData';
import { useLogicContext } from '../logic/Logic';
import { ResultsState } from '../logic/results';
import { BsCheckLg, BsXLg } from 'react-icons/bs';
import { typesList } from '../../../assets/icons/types';
import Tip from './Tip';
import { AiFillHome, AiFillStar, AiOutlineRedo } from 'react-icons/ai';
import { levelProgressionData } from '../../levelselect/progressionData';

export const Results = () => {

	const {setGameState, selectedLevel, gameState, setLevelProgression, levelProgression,setSelectedLevel} = useGlobalContext();
	const {results} = useLogicContext();

	const [_results, _setResults] = useState<ResultsState>();
	const [_selectedLevel, _setSelectedLevel] = useState<number>(0);


	useEffect(() => {
		//only set values at the start in order to prevent popping data during exit transitions.
		if(gameState === 3){
			_setResults(results);
			_setSelectedLevel(selectedLevel);
			if(results.complete && selectedLevel === levelProgression){
				//unlock new level when level is completed
				setLevelProgression(a => a + 1);
			}

		}
	}, [gameState]);

	const retry = () => {
		setGameState(2);
	};

	const onHome = () => {
		setGameState(1);
	};

	const nextLevelAvailable = useMemo(() => {
		return (
			selectedLevel === levelProgressionData.length - 1 ? false // selectedLevel is the last level
				: selectedLevel + 1 <= levelProgression // next level is unlocked by levelProgression
		);
	},[levelProgression, gameState]);

	const nextLevel = () => {
		if(!nextLevelAvailable) return;
		setSelectedLevel(selectedLevel + 1);
		setGameState(2);
	};

	return (
		<Container>
			{_results && _results.complete === true ? 
				<>
					<h1>Well done!</h1> 			
					<p>You have completed <b>{ld[_selectedLevel].name}</b></p>
				</>
				:
				<>				
					<h1>Nice try, but...</h1>
					<p>You have not completed the level</p>
				</>
			}
			{/* <br/>
			<div>{`you completed ${_completedDeliveries}/${levelData.deliveries.length} deliveries`}</div>
			<br/> */}
			<TableContainer>
				<StyledTable>
					<thead>
						<tr>					
							<StyledTH>Delivery</StyledTH>
							<StyledTH>Done</StyledTH>
							<StyledTH># of mistakes</StyledTH>
							<StyledTH>Types wrong</StyledTH>
						</tr>
					</thead>
					{/*actual results */}
					<tbody>
						{ _results && _results.deliveries.map((delivery, index) => 
							<tr key={`results-by-delivery-${index}`}>
								<StyledTD>{index + 1}</StyledTD>						
								<StyledTD>{delivery.complete ? <BsCheckLg color='#2f9711'/> : <BsXLg color='red'/>}</StyledTD>
								<StyledTD>{Object.values(delivery.amountOfMistakes).length}</StyledTD>
								<StyledTD>
									<div style={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
										{ Object.keys(delivery.amountOfMistakes).map((type) => 
											<Icon url={typesList[parseInt(type)-1]} key={`mistakesByType-${type}`} style={{}}></Icon> 
										)}
									</div>
								</StyledTD>
							</tr>)}
					</tbody>
				</StyledTable>
			</TableContainer>
			{_results && <Tip results={_results}/>}
			<Row>
				<Button onClick={retry}><AiOutlineRedo/>Retry<AiFillHome/></Button>
				<Button onClick={onHome}><AiFillHome/>Home<AiFillHome/></Button>
			</Row>
			<Button style={{width: '100%'}} disabled={!nextLevelAvailable} onClick={() => nextLevel()}><AiFillStar/>Next level<AiFillStar/></Button>
		</Container>
	);
};

// styled components

const Container = styled.div`
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	& > p {
		margin: 0!important;
	}

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		gap:0.5em;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${p => p.theme.responsive.media.sm}){
		gap:0.5em;
	}

	// Large screens
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		gap:1em;
	}

	// Extra large screens
	@media (min-width: ${p => p.theme.responsive.media.xl}){
		gap:1em;
	}
`;

const TableContainer = styled.div`
	flex-grow: 1;
	width: 100%;
	overflow-y: auto;
`;

const StyledTable = styled.table`
	width: 100%; 
	border-collapse: collapse;
	border: 1px solid ${p => p.theme.colors.primary};
`;

const StyledTH = styled.th`
	color: ${p => p.theme.colors.primary};
	border-right: solid 1px ${p => p.theme.colors.primary}; 
  	border-left: solid 1px ${p => p.theme.colors.primary}; 
	border-bottom: solid 1px ${p => p.theme.colors.primary}; 
	padding: 0.4em;
`;

const Icon = styled.i<{url: string}>`
	display: block;
	height: 2em;
	width: 2em;
	background-image: url(${p => p.url});
	background-size: cover;
	background-position: center;
`;

const Row = styled.div`
	display: flex;
	width: 100%;
	gap: .5em;

	justify-content: space-evenly;

	& > button {
		width: 100%;
	}

`;

const StyledTD = styled.td`
	text-align: center;
	border-right: solid 1px ${p => p.theme.colors.primary}; 
  	border-left: solid 1px ${p => p.theme.colors.primary}; 
	padding: 0.4em;
`;