
import { useGlobalContext } from '../../../contexts/global/GlobalContext';


export const DCGame = () => {

	const {levelProgression, setLevelProgression} = useGlobalContext();

	return(
		<>
			<label>
				level progression: 
				<input onChange={(e) => setLevelProgression && setLevelProgression(parseInt(e.currentTarget.value))} value={levelProgression} type="number" id="quantity" name="quantity"/>
			</label>
		</>
	);
};
