export const levelData : LevelData = [
	{ // level 1
		name: 'The basics',
		description: 'Learn how to bag it up',
		types: false,
		settings: {
			startingTimeInSeconds: 60,
		},		
		deliveries: [
			{ // 1
				bagItems: [
					'basic_sack_1',
					'basic_sack_1',
					'basic_sack_1',
				],
				tutorialIndex: 0,
				dividers: false,
			},
			{ // 2
				bagItems: [
					'basic_sack_1',
					'basic_sack_1',
					'basic_sack_2',
					'basic_sack_2',
				],
				tutorialIndex: 1,
				dividers: false,

			},
			{ // 3
				bagItems: [
					'basic_box_1',
					'basic_pizza_1',
					'basic_sack_1',
					'basic_sack_2',
				],
				tutorialIndex: 2,
				dividers: false,
			},			
			{ // 4
				bagItems: [
					'basic_pizza_1',
					'basic_sack_1',
					'basic_sack_2',
					'basic_sack_3',
				],
				tutorialIndex: 3,
				dividers: false,
			},
			{ // 5
				bagItems: [
					'basic_box_1',
					'basic_box_1',
					'basic_box_1',
					'basic_bag_1',
				],
				bonusTimeInSeconds: 7,
				dividers: false,
			},
			{ // 6
				bagItems: [
					'basic_pizza_1',
					'basic_pizza_2',
					'basic_sack_1',
					'basic_sack_2',
				],
				bonusTimeInSeconds: 7,
				dividers: false,
			},
		]
	},
	{ // level 2
		name: 'Hot N Cold',
		description: 'How to handle this hot stuff?!',
		settings: {
			startingTimeInSeconds: 60,
		},		
		deliveries: [			
			{ // 1
				bagItems: [
					'cold_sack_1',
					'hot_sack_2',
					'basic_sack_3'
				],
				bonusTimeInSeconds: 7,
				tutorialIndex: 5,
				dividers: false,
			},
			{ // 2
				bagItems: [
					'cold_box_1',
					'hot_box_1',
					'cold_sack_3'
				],
				bonusTimeInSeconds: 7,
				tutorialIndex: 6,
				dividers: false,
			},
			{ // 3
				bagItems: [
					'cold_pizza_2',
					'hot_pizza_2',
				],
				bonusTimeInSeconds: 7,
				tutorialIndex: 7,
			},
			{ // 4
				bagItems: [
					'cold_pizza_1',
					'cold_sack_2',
					'hot_sack_2',
					'hot_pizza_1',
				],
				bonusTimeInSeconds: 7,
				tutorialIndex: 8,
			},
			{ // 5
				bagItems: [
					'hot_pizza_1',
					'hot_pizza_1',
					'cold_pizza_2',
					'cold_sack_1',
					'cold_sack_2',
				],
				bonusTimeInSeconds: 7,
			},
			{ // 6
				bagItems: [
					'cold_bag_1',
					'cold_sack_2',
					'cold_sack_2',
					'cold_sack_2',
					'hot_bag_1',
				],
				bonusTimeInSeconds: 7,
			},
		]
	},
	{ // level 3
		name: 'Practicing',
		description: 'Challenge your skills',
		settings: {
			startingTimeInSeconds: 60,
		},		
		deliveries: [
			{ // 1
				bagItems: [
					'hot_pizza_1',
					'hot_pizza_1',
					'hot_sack_1',
					'cold_bag_1',
					'basic_sack_2'
				],
				bonusTimeInSeconds: 7,
			},
			{ // 2
				bagItems: [
					'hot_box_1',
					'hot_box_1',
					'cold_box_1',
					'cold_box_1',
					'hot_pizza_1'
				],
				bonusTimeInSeconds: 7,
			},
			{ // 3
				bagItems: [
					'basic_sack_2',
					'basic_pizza_2',
					'cold_sack_2',
					'hot_sack_1',
					'hot_sack_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 4
				bagItems: [
					'cold_pizza_1',
					'hot_pizza_2',
					'hot_sack_1',
					'cold_sack_1',
					'cold_sack_2',
					'hot_sack_1',
					'cold_sack_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 5
				bagItems: [
					'cold_sack_3',
					'hot_pizza_1',
					'cold_sack_2',
					'hot_sack_2'
				],
				bonusTimeInSeconds: 7
			},
			{ // 6
				bagItems: [
					'cold_box_1',
					'hot_box_1',
					'hot_pizza_2',
					'cold_pizza_1',
					'cold_sack_1',
					'cold_sack_2'
				],
				bonusTimeInSeconds: 7
			},
		]
	},
	{ // level 4
		name: 'Ice cold',
		description: 'Introducing the coolest items in the game',
		settings: {
			startingTimeInSeconds: 60,
		},		
		deliveries: [
			{ // 1
				bagItems: [
					'cold_sack_3',
					'frozen_sack_3',
					'basic_pizza_1',
				],
				bonusTimeInSeconds: 7,
				tutorialIndex: 9
			},
			{ // 2
				bagItems: [
					'hot_pizza_2',
					'frozen_bag_1',
					'basic_sack_2',
					'basic_box_1',
					'cold_box_1'
				],
				bonusTimeInSeconds: 7,
				tutorialIndex: 10
			},
			{ // 3
				bagItems: [
					'cold_sack_3',
					'hot_sack_2',
					'frozen_sack_1',
					'hot_pizza_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 4
				bagItems: [
					'cold_pizza_2',
					'hot_pizza_1',
					'frozen_pizza_1',
					'cold_sack_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 5
				bagItems: [
					'hot_box_1',
					'hot_box_1',
					'hot_pizza_1',
					'cold_bag_1',
					'frozen_sack_1',
					'frozen_box_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 6
				bagItems: [
					'cold_pizza_1',
					'hot_pizza_2',
					'frozen_sack_1',
					'cold_sack_2',
					'hot_box_1',
					'frozen_box_1'
				],
				bonusTimeInSeconds: 7
			},
		]
	},
	{ // level 5
		name: 'Exercise',
		description: 'Practice makes perfect',
		settings: {
			startingTimeInSeconds: 45,
		},		
		deliveries: [
			{ // 1
				bagItems: [
					'hot_box_1',
					'hot_box_1',
					'basic_bag_1',
					'cold_pizza_1',
					'hot_pizza_1', 
				],
				bonusTimeInSeconds: 7
			},
			{ // 2
				bagItems: [
					'cold_pizza_1',
					'hot_pizza_1',
					'frozen_pizza_2',
					'cold_sack_1',
					'hot_sack_1'					
				],
				bonusTimeInSeconds: 7
			},
			{ // 3
				bagItems: [
					'cold_bag_1',
					'hot_sack_2',
					'frozen_sack_2',
					'hot_pizza_1',
					'frozen_sack_1',
					'cold_sack_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 4
				bagItems: [
					'frozen_sack_3',
					'hot_box_1',
					'hot_box_1',
					'cold_box_1',
					'cold_box_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 5
				bagItems: [
					'hot_pizza_1',
					'frozen_box_1',
					'cold_pizza_2'
				],
				bonusTimeInSeconds: 7
			},
			{ // 6
				bagItems: [
					'frozen_sack_1',
					'frozen_sack_1',
					'frozen_sack_1',
					'cold_sack_1',
					'hot_sack_2',
					'hot_pizza_2'
				],
				bonusTimeInSeconds: 7
			},
			{ // 7
				bagItems: [
					'hot_sack_1',
					'frozen_sack_2',
					'basic_bag_1',
					'frozen_box_1',
					'cold_box_1',
					'hot_pizza_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 8
				bagItems: [
					'frozen_box_1',
					'basic_sack_3',
					'cold_sack_2',
					'basic_sack_2',	
					'basic_bag_1'	
				],
				bonusTimeInSeconds: 7
			}
		]
	},	{ // level 6
		name: 'A balancing act',
		description: 'Handle heavy and light items',
		settings: {
			startingTimeInSeconds: 60,
		},		
		deliveries: [
			{ // 1
				bagItems: [
					'heavy_pizza_2',
					'light_pizza_2'
				],
				bonusTimeInSeconds: 7,
				tutorialIndex: 11
			},
			{ // 2
				bagItems: [
					'hot_heavy_box_1',
					'hot_heavy_box_1',
					'cold_light_sack_3',
					'cold_light_bag_1',
					'cold_heavy_box_1'
				],
				bonusTimeInSeconds: 7,
				tutorialIndex: 12
			},
			{ // 3
				bagItems: [
					'heavy_sack_1',
					'light_sack_1',
					'light_sack_1',
					'heavy_bag_1',
					'heavy_pizza_2'
				],
				bonusTimeInSeconds: 7
			},
			{ // 4
				bagItems: [
					'heavy_bag_1',
					'light_bag_1',
					'light_sack_3',
					'heavy_box_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 5
				bagItems: [
					'cold_heavy_box_1',
					'hot_heavy_box_1',
					'cold_light_box_1',
					'hot_light_box_1',
					'hot_heavy_pizza_2'
				],
				bonusTimeInSeconds: 7
			},
			{ // 6
				bagItems: [
					'cold_light_sack_3',
					'hot_heavy_pizza_1',
					'hot_heavy_sack_1',
					'cold_light_sack_2'
				],
				bonusTimeInSeconds: 7
			},
		]
	},
	{ // level 7
		name: 'The exam',
		description: 'Flex your progress',
		settings: {
			startingTimeInSeconds: 45,
		},		
		deliveries: [
			{ // 1
				bagItems: [
					'cold_heavy_bag_1',
					'hot_light_bag_1',
					'hot_heavy_bag_1',
					'cold_light_bag_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 2
				bagItems: [
					'hot_light_pizza_1',
					'cold_heavy_sack_2',
					'hot_heavy_pizza_1',
					'hot_light_sack_1',
					'cold_light_box_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 3
				bagItems: [
					'hot_heavy_box_1',
					'hot_heavy_box_1',
					'cold_light_box_1',
					'cold_light_sack_3',
					'cold_light_box_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 4
				bagItems: [
					'cold_light_pizza_1',
					'frozen_heavy_sack_2',
					'hot_heavy_pizza_1',
					'cold_heavy_pizza_2'
				],
				bonusTimeInSeconds: 7
			},
			{ // 5
				bagItems: [
					'cold_heavy_sack_1',
					'frozen_heavy_sack_1',
					'cold_light_sack_1',
					'frozen_light_sack_1',
					'hot_heavy_bag_1',
					'hot_heavy_pizza_2'
				],
				bonusTimeInSeconds: 7
			},
			{ // 6
				bagItems: [
					'frozen_heavy_bag_1',
					'cold_light_sack_2',
					'hot_heavy_sack_2',
					'hot_heavy_sack_2',
					'hot_heavy_sack_2',
					'hot_heavy_sack_2'
				],
				bonusTimeInSeconds: 7
			},
		]
	},
	{ // level 8
		name: 'The ultimate challenge',
		description: 'Test all your skills in this delivery extravaganza!',
		settings: {
			startingTimeInSeconds: 45,
		},		
		deliveries: [
			{ // 1
				bagItems: [
					'cold_heavy_bag_1',
					'hot_light_bag_1',
					'frozen_heavy_bag_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 2
				bagItems: [
					'cold_heavy_pizza_1',
					'hot_light_pizza_1',
					'frozen_heavy_pizza_1',
					'frozen_heavy_pizza_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 3
				bagItems: [
					'cold_heavy_box_1',
					'light_box_1',
					'frozen_heavy_box_1',
					'frozen_light_box_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 4
				bagItems: [
					'frozen_heavy_bag_1',
					'frozen_light_sack_2',
					'hot_heavy_pizza_1',
					'cold_light_sack_1',
					'cold_heavy_sack_2',
					'cold_light_box_1',
					'hot_heavy_box_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 5
				bagItems: [
					'frozen_heavy_pizza_1',
					'frozen_light_pizza_1',
					'cold_light_sack_1',
					'hot_heavy_bag_1',
					'hot_light_bag_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 6
				bagItems: [
					'cold_sack_3',
					'hot_sack_3',
					'frozen_sack_1',
					'hot_pizza_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 7
				bagItems: [
					'basic_sack_1',
					'basic_sack_1',
					'basic_box_1',
					'basic_bag_1',
					'basic_pizza_1',
					'basic_sack_1',
					'basic_pizza_1',
					'basic_sack_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 8
				bagItems: [
					'cold_sack_2',
					'frozen_light_sack_2',
					'frozen_light_sack_2',
					'frozen_sack_2',
					'hot_heavy_sack_2'
				],
				bonusTimeInSeconds: 7
			},
			{ // 9
				bagItems: [
					'frozen_sack_1',
					'frozen_sack_1',
					'cold_sack_1',
					'hot_heavy_bag_1',
					'hot_pizza_1',
					'hot_pizza_1'
				],
				bonusTimeInSeconds: 7
			},
			{ // 10
				bagItems: [
					'frozen_light_sack_3',
					'light_box_1',
					'frozen_light_bag_1',
					'frozen_heavy_sack_2',
					'cold_light_sack_1',
					'frozen_heavy_sack_1'
				],
				bonusTimeInSeconds: 7
			},
		]
	},
];

type LevelData = Level[];
export type Level = {
	name: string,
	description: string,
	types?: boolean,
	settings: {
		startingTimeInSeconds: number;
	}
	deliveries: Delivery[]
}
type Delivery = {
	bagItems: string[] // index of item in bagItems
	bonusTimeInSeconds?: number
	tutorialIndex?: number	
	dividers?: boolean

}

