import { keyframes } from 'styled-components';
import Theme from './theme';

export const SlideIn = keyframes`
    0% {
        transform: translate(0, 200%);
    }
    100% {
        transform: translate(0, 0);
    }
`;

export const SlideOut = keyframes`
    100% {
        transform: translate(0, 200%);
    }
    0% {
        transform: translate(0, 0);
    }
`;

export const SlideOutRight = keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(200%);
    }
`;

export const SlideInLeft = keyframes`
    0% {
        transform: translateX(-200%);
    }
    100% {
        transform: translateX(0%);
    }
`;

export const SlideInTop = keyframes`
    0%{
        transform: translateY(-200%);
    }
    100%{
        transform: translateY(0);
    }
`;

export const SlideOutTop = keyframes`
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(-200%);
    }
`;

export const ScaleUp = keyframes`
    0%{
        transform: scale(1,0);
    }
    100%{
        transform: scale(1,1);
    }
`;

export const FadeIn = keyframes`
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`;