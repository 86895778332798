import { useEffect } from 'react';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';

const amountOfStates = 5;

export const DCGeneral = () => {

	const {gameState, setGameState, overwriteGameState, setOverwriteGameState} = useGlobalContext();

	useEffect(() => {
		localStorage.setItem('overwriteGameState', JSON.stringify(overwriteGameState));
	},[overwriteGameState]);

	return(
		<>
			<button onClick={() => {localStorage.clear(); location.reload();}}>Clear local storage</button>
			<label>
				gameState
				<select onChange={(e) => setGameState && setGameState(parseInt(e.currentTarget.value))} value={gameState}>
					{ [...Array(amountOfStates)].map((option, index) => <option key={`dc-general-gamestate-option-${index}`}>{index}</option>)}
				</select>
			</label>
			
			<label>
				overwrite initial gameState
				<select onChange={(e) => setOverwriteGameState && setOverwriteGameState(parseInt(e.currentTarget.value))} value={overwriteGameState}>
					<option>no overwrite</option>
					{ [...Array(amountOfStates)].map((option, index) => <option key={`dc-general-gamestate-overwrite-option-${index}`}>{index}</option>)}
				</select><br/>
			</label>

		</>
	);
};