import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';
import Theme from './theme';

const CustomThemeProvider = (props: {children: ReactNode}) => {
	return(
		<ThemeProvider theme={Theme}>
			<GlobalStyle/>
			{props.children}
		</ThemeProvider>
	);
};

export default CustomThemeProvider;