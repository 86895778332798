import gridsize1 from './gridsize1.png';
import gridsize2 from './gridsize2.png';
import gridsize3 from './gridsize3.png';
import gridsize4 from './gridsize4.png';
import gridsize5 from './gridsize5.png';
import gridsize6 from './gridsize6.png';
import gridsize7 from './gridsize7.png';

export const sizeList: size = {
	'11': gridsize1,
	'12': gridsize2,
	'31': gridsize3,
	'22': gridsize4,
	'32': gridsize5,
	'21': gridsize6,
	'23': gridsize7,

};

interface size {
    [key: string]: string;
}