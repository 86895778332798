const Theme = {
	colors:{
		neutralLightest: 'hsl(250deg 0% 100%)',
		neutralLight: 'hsl(250deg, 0%, 75%)',
		neutral: '#666C99',
		neutralDarkest: 'hsl(0, 0%, 0%)',//black
		neutralDarker: 'hsl(250deg 0% 15%)',//almost black
		neutralDark: 'hsl(0, 0%, 38%)',//darkgrey
		primaryLightest: 'hsl(30, 100%, 80%)',//orange
		primaryLight: 'hsl(30, 100%, 60%)',//orange
		primary: 'hsl(30, 100%, 50%)',//orange
		primaryDark: 'hsl(28, 97%, 48%)',//darkorange
		primaryDarker: 'hsl(28, 97%, 15%)'
	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1025px',
			xl: '1440px',
		},
		whitespace: {
			xs: 15,
			sm: 25,
			lg: 25,
			xl: 30,
		}
	}
};

export default Theme;
