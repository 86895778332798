import { checkItemsForOtherTypes, TypeCheck } from './rules';

export const check : TypeCheck = (state, itemId) => {

	// heavy may not be placed on other items that are not heavy

	const itemsOnBottom = state.items[itemId].placedOnTopOf;

	const result = {correct: !checkItemsForOtherTypes(state, [ ...itemsOnBottom], 1)};

	return result;
};